import Vue from 'vue'
import Router from 'vue-router'

import Login from './Login.vue'
import Reg from './Reg.vue'
import RegSaas from './RegSaas.vue'
import RegHrsaas from './RegHrsaas.vue'
import ForgotPass from './ForgotPass.vue'
import ResetPass from './ResetPass.vue'
import Loading from './Loading.vue'
Vue.use(Router)

 
export default new Router({
    routes: [

        { path: '*', redirect: '/login' },
        {//Saas系统入口页面，
            path: '/login/saas/:fromUrl?',
            name: 'login',
            props: route => ({
                saas: { default: 'true' },
                fromUrl: route.query.fromUrl
            }),
            meta: {
                title: '登录'
            },
            component: Login

        },
        {//人力Saas系统入口页面，
            path: '/login/hrsaas',
            name: 'login',
            meta: {
                title: '登录'
            },
            component: Login

        },
        {//系统入口页面，
            path: '/login/:mobile?',
            name: 'login',
            props:true,
            meta: {
                title: '登录'
            },
            component: Login 
             
        },
        {
            path: '/reg/:saas',
            name: 'reg',
            props:true,
            meta: {
                title: '注册'
            },
            component: Reg 
            
        },
        {
            path: '/forgotPass',
            name: 'forgotPass',
            meta: {
                title: '忘记密码'
            },
            component: ForgotPass 
        },
        {
            path: '/resetPass',
            name: 'resetPass',
            meta: {
                title: '密码重置'
            },
            component: ResetPass 
        },
        {
            path: '/regSaas/:type?',
            name: 'regSaas',
            meta: {
                title: 'Saas注册'
            },
            component: RegSaas
        },
        {
            path: '/regHrsaas',
            name: 'regHrsaas',
            meta: {
                title: '工众人力SaaS注册'
            },
            component: RegHrsaas
        },
        {
            path: '/loading',
            name: 'loading',
            meta: {
                title: '加载中'
            },
            component: Loading
        },

    ]
})
