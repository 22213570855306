<template>
  <div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
      "
    >
      <div></div>
      <div style="display: flex; align-items: center">
        <el-input
          class="search-input"
          v-model="name"
          placeholder="工厂机构名称"
          style="width: 180px"
          clearable
        >
        </el-input>
        <el-button type="primary" @click="search()">搜索</el-button>
        <el-button @click="reset()">重置</el-button>
      </div>
    </div>
    <div>
      <el-table
        ref="detailListRef"
        v-loading="detail_grid_loading"
        :data="detailListData"
        border
        style="width: 100%"
        height="400px"
        highlight-current-row
        @current-change="handleCurrentChange"
      >
        <el-table-column
          type="index"
          width="50"
          align="center"
          reserve-selection
        ></el-table-column>
        <el-table-column
          prop="organName"
          label="工厂机构"
          width="240"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="shipperName"
          label="所属发货人"
          width="240"
          align="center"
        ></el-table-column>
        <el-table-column label="选择" align="center">
          <template>
            <i class="el-icon-check is-row-chekced"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div style="margin-top: 5px; display: flex; justify-content: end">
      <el-pagination
        background
        layout="sizes, prev, pager, next, total"
        :total="detail_grid_total"
        :page-size.sync="detail_grid_pageSize"
        @current-change="detail_pageChange"
        :current-page.sync="detail_grid_pageNo"
      ></el-pagination>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import userAPI from "@/api/userAPI.js";
import sysAPI from "@/api/sysAPI.js";
import aEntityAPI from "@/api/aEntityAPI.js";
export default {
  components: {},
  mixins: [],
  props: {
    organList: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      __shipper: "user/shipper",
      userinfo: "user/userinfo",
    }),
  },
  mounted() {
    this.currentRow = null;
    this.detailListData = this.organList;
  },
  data() {
    return {
      //   detail_grid_total: 0,
      //   detail_grid_pageSize: 20,
      //   detail_grid_pageNo: 1,
      name: "",
      detail_grid_loading: false,
      detailListData: [],
      currentRow: null,
    };
  },
  methods: {
    // detail_pageChange() {},
    handleCurrentChange(val) {
      this.currentRow = val;
      this.$emit('selectOrgan',val);
    },
    reset() {
      this.name = "";
    },
    search() {
      let _this = this;
      _this.currentRow = null;//取消选择机构
      let params = {
        user: JSON.stringify(_this.userinfo),
        name: _this.name,
      };
      _this.detail_grid_loading = true;
      aEntityAPI.request("YhService", "yhOrganList", params).then((res) => {
        _this.detail_grid_loading = false;
        if (res.data.success) {
          _this.detailListData = res.data.data;
        } else {
          _this.$message.error(res.data.msg || "查询机构异常");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .search-input .el-input__inner {
  height: 36px !important;
}
</style>