<template>
</template>
<script>
import { mapActions } from "vuex";

export default {

    data() {
        return {

        }
    },

    methods: {
        ...mapActions(["user/login", "user/logout"])
    },
    mounted() {
        const query = this.$route.query;

        const loading = this.$loading({
            lock: true,
            text: '正在载入...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });


        this["user/logout"]().then( res => {
            if(res){
                this["user/login"]({
                    loginName: query.mobile,
                    password: "Test1111"
                }).then(data => {
                    if (data.success) {
                        loading.close();
                        if(query.env == 1){
                            this.$router.replace('/home')
                        }else if(query.env == 5){
                            window.location.href = "common.html#/shipperAuth";
                        }else{
                            window.location.href = "saas56.html#/home";
                        }
                    }else{
                        this.$message.error(data.msg);
                    }
                });
            }
        });;
        
    }
};
</script>
