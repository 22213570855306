<template>
    <div style="height:100%; background-color: #2d3a4b;" v-if="!isLoading">
        <div class="img-wrap" v-if="!isRegSaas" v-html="loginBanner"></div>
        <div class="img-wrap" v-if="isRegSaas">
            <p>
                <img src="@/assets/img-wap.png" style="max-width: 100%"/>
            </p>
        </div>
        <div class="login-header">
            <div class="login-header-content">
                <div class="login-logo">
                    <img v-if="isRegSaas || isLoginSaas" :src="saasImg"/>
                    <img v-else-if="isHrsaasReg || isHrsaaslogin" :src="hrsaasImg"/>
                    <img v-else :src="ftpPath + (sysConfig&&sysConfig.logoFile?sysConfig.logoFile.filePath:'')"/>

                    <span class="sys-name" style="font-family: ZKGDH;font-size: 25px" v-if="isRegSaas || isLoginSaas">共生物流SAAS平台</span>
                    <span class="sys-name" style="font-family: ZKGDH;font-size: 25px"
                          v-else-if="isHrsaasReg || isHrsaaslogin">工众人力SAAS平台</span>
                    <span class="sys-name" v-else>{{sysConfig&&sysConfig.name?sysConfig.name:''}}</span>
                </div>
                <div class="login-home-btn">
                    <el-button
                            v-if="firstLogin"
                            type="text"
                            style="margin-right: 20px;font-size:16px;"
                            @click="signOut"
                    >退出
                    </el-button>
                    <el-button type="text" style="margin-right: 20px;font-size:16px;" @click="home">返回首页</el-button>
                </div>
            </div>
        </div>
        <!-- <div :class="{'login-container':!isRegSaas && !isHrsaasReg,'login-container-saas':isRegSaas || isHrsaasReg,'login-container-saas-big':(isBig && (isRegSaas || isHrsaasReg)),'login-container-saas-min': (!isBig && (isRegSaas || isHrsaasReg))}">
            <router-view></router-view>
        </div> -->

        <div v-if="!isHrsaasReg" :class="{'login-container':!isRegSaas && !isHrsaasReg,'login-container-saas':isRegSaas || isHrsaasReg,'login-container-saas-big':(isBig && (isRegSaas || isHrsaasReg)),'login-container-saas-min': (!isBig && (isRegSaas || isHrsaasReg))}">
            <router-view></router-view>
        </div>

        <div v-if="isHrsaasReg" :class="{'login-container':!isRegSaas && !isHrsaasReg,'login-container-saas':isRegSaas || isHrsaasReg,'login-container-saas-big-hr':(isBig && (isRegSaas || isHrsaasReg)),'login-container-saas-min': (!isBig && (isRegSaas || isHrsaasReg))}">
            <router-view></router-view>
        </div>
    </div>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import userAPI from "@/api/userAPI.js";
    import ls from "@/utils/ls";

    export default {
        data() {
            return {
                ftpPath: this.__ftpPath,
                loginBanner: "",
                firstLogin: false,
                isRegSaas: false,
                isLoginSaas: false,
                isBig: false,
                isHrsaaslogin: false,
                isHrsaasReg: false,
                hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
                saasImg: require("@/assets/saas_logo.png"),
                isLoading: false
            };
        },
        computed: {
            ...mapGetters({
                sysConfig: "config/sysConfig"
            }),
            ...mapActions(["config/setConfig"])
        },
        created() {
            this["config/setConfig"];
            if (ls.get("login_info")) {
                this.firstLogin = true;
            }
        },
        methods: {
            getLoginBanner() {
                
                if(this.isHrsaaslogin || this.isHrsaasReg){
                    userAPI.getHrSaaSDomain().then(r => {
                        if (r.data.success) {
                            if (r.data.data){
                                //使用人力的域名去请求配置

                                userAPI.getLoginBanner({"@TenantDomain": r.data.data}).then(response => {
                                    if (response.data.success) {
                                        if (response.data.data)
                                            this.loginBanner = response.data.data.replaceAll("&nbsp;", "");
                                    }
                                });
                            } 
                        }
                    });
                } else {
                    userAPI.getLoginBanner().then(response => {
                        if (response.data.success) {
                            if (response.data.data)
                                this.loginBanner = response.data.data.replaceAll("&nbsp;", "");
                        }
                    });
                }                                
            },
            home() {
                var url = window.location.href;
                var serverHost = window.location.host;
                if (serverHost.indexOf("hrsaas.") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
                    window.location.href = "/hrsaas.html#/hrsaas";
                } else if (url.indexOf("saas") > -1 || url.indexOf("Saas") > -1) {
                    window.location.href = "/saas.html#/saas";
                } else {
          
                    if(this.sysConfig.showCommonHomeTag){
                        window.location.href = "/hPage.html#/hPage";
                    }else{
                        window.location.href = "/index";
                    }
     
                   
                }
            },

            isSaasReg() {
                var serverHost = window.location.host;

                if (this.$route.path.indexOf("login") > -1 && (serverHost.indexOf("hrsaas.") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1)) {
                    this.isHrsaaslogin = true
                } else if (this.$route.path.indexOf("regHrsaas") > -1 && (serverHost.indexOf("hrsaas.") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1)) {
                    this.isHrsaasReg = true
                } else if (this.$route.path.indexOf("regSaas") > -1 && serverHost.indexOf("saas.") > -1) {
                    this.isRegSaas = true
                } else if (this.$route.path.indexOf("login") > -1 && serverHost.indexOf("saas.") > -1) {
                    this.isLoginSaas = true
                }

                console.log("this.isHrsaaslogin:" + this.isHrsaaslogin)
                console.log("this.isHrsaasReg:" + this.isHrsaasReg)
                console.log("this.isRegSaas:" + this.isRegSaas)
                console.log("this.isLoginSaas:" + this.isLoginSaas)

            },

            signOut() {
                this.firstLogin = false;
                ls.clear2();
                window.location.href = "/login.html";
            },
            goBack() {
                window.location.reload()
            }
        },
        watch: {
            $route(to, from) {
                let that = this;
                this.isRegSaas = to.path.indexOf("regSaas") > -1
                this.isLoginSaas = to.path.indexOf("saas") > -1
            },

            sysConfig: function (nval, oval) {
                if (nval && nval.logoFile && nval.logoFile.filePath) {
                    var url = window.location.href;
                    if (url.indexOf("loginTL") !== -1) {
                        this.sysConfig.name = "安徽共生物流科技有限公司铜陵分公司";
                        this.sysConfig.logoFile = ""
                    } else if (url.indexOf("loginHY") !== -1) {
                        this.sysConfig.name = "安徽共生物流科技有限公司红杨分公司";
                        this.sysConfig.logoFile = ""
                    } else if (url.indexOf("loginBB") !== -1) {
                        this.sysConfig.name = "安徽共生物流科技有限公司蚌埠分公司";
                        this.sysConfig.logoFile = ""
                    } else if (url.indexOf("loginWHWH") !== -1) {
                        this.sysConfig.name = "芜湖网货供应链管理有限公司";
                        this.sysConfig.logoFile = ""
                    }

                    if (this.sysConfig.logoFile && this.sysConfig.logoFile.filePath) {
                        var link = document.createElement("link");
                        link.type = "image/x-icon";
                        link.rel = "shortcut icon";
                        var serverHost = window.location.host;
                        if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
                            link.href = this.hrsaasImg;
                        } else if (serverHost.indexOf("saas") > -1) {
                            link.href = this.saasImg;
                        } else {
                            link.href = this.__ftpPath + nval.logoFile.filePath;
                        }
                        document.getElementsByTagName("head")[0].appendChild(link);
                    }
                }
            }
        },
        mounted() {
            this.isSaasReg();
            this.getLoginBanner();
            var windowWidth = document.documentElement.clientWidth;
            if (windowWidth > 1366) {
                this.isBig = true
            }


            if (window.history) {
                // history.pushState(null, "", document.URL); //这里有没有都无所谓，最好是有以防万一
                window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
            }


            var url = window.location.href;
            if(url.indexOf("loading")>-1){
                this.isLoading = true;
            }

        }
    }
    ;
</script>
<style>
    #app,
    html {
        height: 100%;
    }

    body {
        height: 100%;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Arial, sans-serif;
    }

    .login-header {
        background-color: rgba(45, 58, 75, 0.7);
        color: #fff;
        position: fixed;
        top: 0;
        width: 100%;
        border: none;
        height: 100px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    }

    .login-header-content {
        width: 1200px;
        margin: 0 auto;
        height: 100%;
    }

    .login-logo {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        padding: 20px 0;
        vertical-align: top;
    }

    .login-logo img {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        margin-bottom: 15px;
    }

    .sys-name {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        overflow: visible;
        padding-left: 20px;
    }

    .login-home-btn {
        vertical-align: top;
        width: 200px;
        display: inline-block;
        height: 60px;
        line-height: 60px;
        padding: 20px 0;
        float: right;
        text-align: right;
    }

    .login-home-btn button {
        margin-right: 0;
    }

    .login-container {
        width: 420px;
        height: 470px;
        background-color: #fff;
        overflow: hidden;
        z-index: 2;
        position: absolute;
        top: 160px;
        right: 10%;
    }

    .login-container-saas {
        /*width: 1274px;*/
        /*height: 700px;*/
        background-color: #ffffff;
        overflow: hidden;
        z-index: 2;
        /*position: absolute;*/
        position: relative;

    }

    .login-container-saas-big {
        /*margin: auto 15%;*/
        margin: auto;
        top: 125px;
        width: 40%;
        /* height: 60%; */
        overflow-y: auto;
        overflow-x: hidden;
    }
    .login-container-saas-big-hr {
        /*margin: auto 15%;*/
        margin: auto;
        top: 125px;
        width: 45%;
        height: 65%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .login-container-saas-min {
        /*left: 3%;*/
        top: 120px;
        margin: auto;
        width: 90%;
        overflow: auto;
        height: 70%;
    }

    .login-banner-wrap {
        display: inline-block;
        width: 700px;
        height: 100%;
        border-right: 1px solid #eee;
    }

    .img-wrap {
        position: fixed;
        width: 100%;
        height: 100%;
    }

    .img-wrap br {
        display: none;
    }

    .img-wrap img {
        position: fixed;
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .login-container .login-form {
        display: inline-block;
        width: 380px;
        height: 100%;
        overflow: hidden;
        left: 20px;
        top: 20px;
        position: relative;
    }

    .login-title {
        position: relative;
    }

    .login-title h3 {
        font-size: 26px;
        margin: 0 auto 25px auto;
        font-weight: 700;
        text-align: center;
        color: #565656;
    }

    .login-container .el-input input {
        padding: 12px 5px 12px 15px;
        height: 47px;
    }

    /*
    .login-container .el-form-item {
      border: 1px solid hsla(0, 0%, 100%, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    } */

    /* 注册成功界面 */
    .login-container .success-form {
        display: inline-block;
        width: 380px;
        height: 100%;
        overflow: hidden;
        left: 20px;
        top: 20px;
        position: relative;
    }

    .login-container .largeTitle {
        font-size: 30px;
        text-align: center;
        line-height: 60px;
        margin-bottom: 20px;
    }

    .login-container .noralTitle {
        font-size: 18px;
        text-align: center;
    }

    .login-container .divclass {
        margin-bottom: 40px;
    }

    .img-url {
        background-image: url(../assets/img-wap.png);
        max-width: 100%;
    }

    @font-face {
        font-family: ZKGDH;
        src: url("../font/iconfont_zkgdh.TTF");
    }
</style>