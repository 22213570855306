<template>
    <div class="" style="background-color: #ffffff">

        <div class="login-title" style="margin-top: 40px;margin-bottom: 0px">
            <h3>{{hrsaas}}注册SaaS账号</h3>
        </div>




        <div class="saas_reg_border">

            <el-row :gutter="10">

                <el-col :span="24" v-show="active == 0">

                    <el-form ref="registFormRef" :model="RegistForm" class="login-form reg_form_input " :rules="rules"
                             :show-message="true"
                             :disabled="active == 1 || active == 2">

                        <!--手机号码-->

                        <el-row :gutter="5">
                            <el-col :span="12" style="margin-top: 20px">
                                <el-form-item prop="mobile" class="info-msg-reg">
                                    <el-input
                                            type="input"
                                            placeholder="手机"
                                            v-model.trim="RegistForm.mobile"
                                            autocomplete="off"
                                            @change="registMobileChange" maxlength="11"
                                    >
                                        <template slot="prepend">
                                            <i class="el-icon-phone"/>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" style="margin-top: 20px">
                                <el-form-item prop="registName" class="info-msg-reg">
                                    <el-input
                                            type="input"
                                            placeholder="姓名"
                                            v-model.trim="RegistForm.registName"
                                            autocomplete="off"
                                    >
                                        <template slot="prepend">
                                            <i class="el-icon-user"/>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>


                        <!--图片验证码-->
                        <el-row style="margin-top: 22px" :gutter="5">
                            <el-col :span="12">
                                <el-form-item prop="registImgCode" class="info-msg-reg" v-show="active==0">
                                    <el-input
                                            type="input"
                                            placeholder="图片验证码"
                                            v-model.trim="RegistForm.registImgCode"
                                            autocomplete="off" maxlength="6"
                                    ></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" style="height:47px">
                                <el-form-item class="info-msg-reg" v-show="active==0">
                                    <el-image v-if="imgCode!=null"
                                              @click="changeImgCode"
                                              fit="fill"
                                              :src="imgCode"
                                              class="form-image-code"
                                    />
                                </el-form-item>

                            </el-col>
                        </el-row>

                        <!--短信验证码-->
                        <el-form-item prop="registCode" class="info-msg-reg" v-show="active == 0">
                            <el-row :gutter="5" type="flex" align="middle" style="margin-top: 12px;">
                                <el-col :span="12">
                                    <el-input
                                            type="input"
                                            placeholder="短信验证码"
                                            v-model.trim="RegistForm.registCode"
                                            autocomplete="off" maxlength="6"
                                    ></el-input>
                                </el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <el-button
                                            type="info"
                                            plain
                                            :disabled="this.getSmsCodeStatus || active ==1 || active == 2"
                                            class="form-sms-code"
                                            @click="getSmsCode('registFormRef')"
                                    >{{this.getSmsCodeName}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <!--登录密码-->
                        <el-form-item prop="registPassword" v-if="showPaswrd" v-show="active == 0">
                            <el-row class="info-msg-reg" style="margin-top: 22px">
                                <el-col :span="24">
                                    <el-input
                                            type="password"
                                            placeholder="设置一个登录密码"
                                            v-model.trim="RegistForm.registPassword"
                                            autocomplete="off" maxlength="20" show-password
                                    >
                                        <template slot="prepend">
                                            <i class="el-icon-lock"/>
                                        </template>
                                    </el-input>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item v-if="!showPaswrd">
                            <el-row class="info-msg-reg" style="margin-top: 20px">
                                <el-col :span="24">
                                    <!--<div style="color: red;font-size: 16px">该手机号号码已经注册,无须设置登录密码</div>-->
                                    <div style="color: #1a1a1a;font-size: 16px">该登录账户已存在</div>
                                    <div style="color: #8c939d;font-size: 16px" v-show="!exist && regMsg">请继续使用在{{regMsg}}的登录密码
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item prop="enterpriseName">
                            <el-row style="margin-top: 22px;">
                                <el-col :span="8">
                                    <el-switch v-model="RegistForm.userType" @change="userTypeChange">
                                    </el-switch>
                                    <span v-if="RegistForm.userType" class="el-radio__label">企业账号</span>
                                    <span v-if="!RegistForm.userType" class="el-radio__label">个人账号</span>
                                </el-col>
                                <el-col :span="16">
                                    <el-input                                        
                                            v-if="RegistForm.userType"
                                            placeholder="请录入企业名称"
                                            v-model.trim="RegistForm.enterpriseName"
                                            autocomplete="off"
                                    >
                                    </el-input>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-button v-show="active == 0"
                                   type="primary"
                                   style="width:100%;margin-top:22px;margin-left: 0%;border-radius: 4px"
                                   @click="registBtnSubmit"
                                   v-loading.fullscreen.lock="fullscreenLoading"
                        >提交账号
                        </el-button>
                    </el-form>

                </el-col>

                <el-col :span="24" v-show="active == 1">
                    <el-form ref="registFormFileRef" :model="RegistForm" :disabled="disabledFile"
                             :show-message="true"
                             class="login-form reg_form_file " :rules="rules">
                        <div style="height: 295px;">

                            <el-row v-if="!RegistForm.userType">
                                <el-col :span="12" :class="{'id_card_file_border':isIdCardFileSuccess}">
                                    <el-form-item prop="idCardFile" :show-message="true">
                                        <box>
                                            <template v-slot:body>
                                                <el-container>
                                                    <el-aside width="100%">
                                                        <box style="text-align: center;">
                                                            <template v-slot:body>
                                                                <el-tooltip :disabled="!disabledFile"
                                                                            content="请先提交账号信息！" placement="top"
                                                                            effect="light">
                                                                    <el-upload class="company-uploader"
                                                                               accept="image/jpeg, image/png, image/jpg"
                                                                               ref="cardUpload"
                                                                               :before-upload="handleBeforeUpload"
                                                                               list-type="picture-card"
                                                                               :show-file-list="false"
                                                                               :on-success="handleRoadSuccessIdCardFile"
                                                                               :action="actionUrl"
                                                                               name="filedata"
                                                                               :data="params">
                                                                        <img v-if="road_idcard_fileUrl"
                                                                             :src="road_idcard_fileUrl"
                                                                             class="company-avatar"/>
                                                                        <el-button v-else icon="el-icon-picture-outline"
                                                                                   class="img-button"
                                                                                   circle></el-button>
                                                                        <div slot="tip" :class="[
                                                                                    {
                                                                                        'el-upload__tip': !disabledFile,
                                                                                        'el-upload-tip-disabled': disabledFile
                                                                                    }
                                                                                
                                                                                ]">上传个人身份证
                                                                        </div>
                                                                    </el-upload>
                                                                </el-tooltip>
                                                            </template>
                                                        </box>
                                                    </el-aside>
                                                </el-container>
                                            </template>
                                        </box>
                                    </el-form-item>
                                </el-col>


                            </el-row>
                            <el-row v-else-if="RegistForm.userType">

                                <el-col :span="12" :class="{'no_file_border':isNoFileSuccess}">
                                    <el-form-item prop="enterpriseFile" :show-message="true">
                                        <box>
                                            <template v-slot:body>
                                                <el-container>
                                                    <el-aside width="100%">
                                                        <box style="text-align: center;">
                                                            <template v-slot:body>
                                                                <el-tooltip :disabled="!disabledFile"
                                                                            content="请先提交账号信息！" placement="top"
                                                                            effect="light">
                                                                    <el-upload class="company-uploader"
                                                                               accept="image/jpeg, image/png, image/jpg"
                                                                               ref="cardUpload"
                                                                               :before-upload="handleBeforeUpload"
                                                                               list-type="picture-card"
                                                                               :show-file-list="false"
                                                                               :on-success="handleRoadSuccessEnterpriseNameFile"
                                                                               :action="actionUrl"
                                                                               name="filedata"
                                                                               :data="params">
                                                                        <img v-if="road_no_fileUrl"
                                                                             :src="road_no_fileUrl"
                                                                             class="company-avatar"/>
                                                                        <el-button v-else icon="el-icon-picture-outline"
                                                                                   class="img-button"
                                                                                   circle></el-button>
                                                                        <div slot="tip" :class="[
                                                                                    {
                                                                                        'el-upload__tip': !disabledFile,
                                                                                        'el-upload-tip-disabled': disabledFile
                                                                                    }

                                                                                ]">上传营业执照
                                                                        </div>
                                                                    </el-upload>
                                                                </el-tooltip>
                                                            </template>
                                                        </box>
                                                    </el-aside>
                                                </el-container>
                                            </template>
                                        </box>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12"
                                        :class="{'authorize_idcard_file_border':isAuthorizeIdCardFileSuccess}">
                                    <el-form-item prop="authorizeIdcardFile" :show-message="true">
                                        <box>
                                            <template v-slot:body>
                                                <el-container>
                                                    <el-aside width="100%">
                                                        <box style="text-align: center;">
                                                            <template v-slot:body>
                                                                <el-tooltip :disabled="!disabledFile"
                                                                            content="请先提交账号信息！" placement="top"
                                                                            effect="light">
                                                                    <el-upload class="company-uploader"
                                                                               accept="image/jpeg, image/png, image/jpg"
                                                                               ref="cardUpload"
                                                                               :before-upload="handleBeforeUpload"
                                                                               list-type="picture-card"
                                                                               :show-file-list="false"
                                                                               :on-success="handleRoadSuccessAuthorizeIdcardFile"
                                                                               :action="actionUrl"
                                                                               name="filedata"
                                                                               :data="params">
                                                                        <img v-if="road_authorize_idcard_fileUrl"
                                                                             :src="road_authorize_idcard_fileUrl"
                                                                             class="company-avatar"/>
                                                                        <el-button v-else icon="el-icon-picture-outline"
                                                                                   class="img-button"
                                                                                   circle></el-button>
                                                                        <div slot="tip" :class="[
                                                                                    {
                                                                                        'el-upload__tip': !disabledFile,
                                                                                        'el-upload-tip-disabled': disabledFile
                                                                                    }

                                                                                ]">上传注册人身份证
                                                                        </div>
                                                                    </el-upload>
                                                                </el-tooltip>
                                                            </template>
                                                        </box>
                                                    </el-aside>
                                                </el-container>
                                            </template>
                                        </box>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item :show-message="true" class="download_template_file_border">

                                        <div>
                                            <div class="download_border">
                                                <el-button icon="el-icon-download"
                                                           @click.stop="downloadAuthorizeFile"
                                                           class="img-button"
                                                           circle></el-button>
                                            </div>
                                            <div :class="[{'download_template_file': !disabledFile,'download_template_file_disabled': disabledFile}]">
                                                下载法人授权模板
                                            </div>
                                        </div>


                                    </el-form-item>
                                </el-col>

                                <el-col :span="12" :class="{'authorize_file_border':isAuthorizeFileSuccess}">
                                    <el-form-item prop="authorizeFile" :show-message="true">
                                        <box>
                                            <template v-slot:body>
                                                <el-container>
                                                    <el-aside width="100%">
                                                        <box style="text-align: center;">
                                                            <template v-slot:body>
                                                                <el-tooltip :disabled="!disabledFile"
                                                                            content="请先提交账号信息！" placement="top"
                                                                            effect="light">
                                                                    <el-upload class="company-uploader"
                                                                               ref="cardUpload"
                                                                               accept=".docx, .pdf, .doc, image/jpeg, image/png, image/jpg, image/bmp"
                                                                               list-type="picture-card"
                                                                               :before-upload="handleBeforeFileUpload"
                                                                               :show-file-list="false"
                                                                               :on-success="handleRoadSuccessAuthorizeFile"
                                                                               :action="actionUrl"
                                                                               name="filedata"
                                                                    >
                                                                            <span v-if="road_authorize_fileUrl">
                                                                                <i style="color:#2CE136"
                                                                                   class="el-icon-check"></i></span>
                                                                        <el-button v-else icon="el-icon-picture-outline"
                                                                                   class="img-button"
                                                                                   circle></el-button>
                                                                        <div slot="tip" :class="[
                                                                                    {
                                                                                        'el-upload__tip': !disabledFile,
                                                                                        'el-upload-tip-disabled': disabledFile
                                                                                    }

                                                                                ]">上传法人授权
                                                                        </div>
                                                                    </el-upload>
                                                                </el-tooltip>
                                                            </template>
                                                        </box>
                                                    </el-aside>
                                                </el-container>
                                            </template>
                                        </box>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </div>
                        <el-row :gutter="10">
                        <el-col :span="18">
                            <el-button v-show="active == 1"
                                   :class="{'reg_form_file_button':RegistForm.enterpriseName != null,'reg_form_file_button_null':RegistForm.enterpriseName == null}"
                                   type="primary" @click="fileSubmit"
                                   v-loading.fullscreen.lock="fullscreenLoading">提交授权
                            </el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button v-show="active == 1 && showSkip"
                                    :class="{'reg_form_file_button':RegistForm.enterpriseName != null,'reg_form_file_button_null':RegistForm.enterpriseName == null}"
                                    type="info" @click="toSkip"
                                    v-loading.fullscreen.lock="fullscreenLoading">跳过
                            </el-button>
                        </el-col>
                        </el-row>
                    </el-form>
                </el-col>

                <el-col :span="24" v-show="active == 2">
                    <el-form ref="registFormProtocolRef" :model="RegistForm" class="login-form reg_form_protocol"
                             :disabled="active == 0 || active == 1" :show-message="true"
                             :rules="rules">

                        <div style="height: 350px;">
                            <el-row>
                                <el-col :span="24"
                                        :class="{'offline_protocol_content_big': isBig,'offline_protocol_content_min': !isBig}">
                                    <el-form-item label="" style="font-weight: bold">
                                        <el-radio-group v-model="RegistForm.type.value">
                                            <el-radio :label="10">在线签订协议</el-radio>
                                            <el-radio :label="11">线下签订协议</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row v-if="RegistForm.type.value == 10">
                                <el-col :span="24" class="info-msg" style="margin-top: 12px;">
                                    <el-form-item>
                                        <div class="protocol_preview_content"
                                             :class="{'protocol_preview_content_disabled': active == 0 || active == 1}"
                                             v-html="protocolContent"></div>
                                        <div class="protocol_content_see" v-if="active ==2 || active ==3"
                                             @click="protocolPreview(true)">查看注册协议详情
                                        </div>
                                        <div class="protocol_content_see_disabled" v-else>查看注册协议详情</div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row v-if="RegistForm.type.value == 11" style="margin-top: 30px">
                                <el-col :span="12">
                                    <el-form-item class="download_template_file_border">
                                        <div>
                                            <div class="download_border" style="margin-left: 43px">
                                                <el-button icon="el-icon-download"
                                                           @click.stop="downloadRegTemplate"
                                                           class="img-button"
                                                           circle></el-button>
                                            </div>
                                            <div style=" color: #589CFD;margin-left: 63px; margin-top: -5px;">
                                                下载协议模板
                                            </div>
                                        </div>


                                    </el-form-item>
                                </el-col>

                                <el-col :span="12" :class="{'protocol_file_border':isProtocolFileSuccess}">
                                    <el-form-item prop="protocolFile" :show-message="true">
                                        <box>
                                            <template v-slot:body>
                                                <el-container>
                                                    <el-aside width="100%">
                                                        <box style="text-align: center;">
                                                            <template v-slot:body>
                                                                <el-upload class="company-uploader"
                                                                           ref="cardUpload"
                                                                           list-type="picture-card"
                                                                           accept=".docx, .pdf, .doc"
                                                                           :show-file-list="false"
                                                                           :before-upload="handleBeforeFileUpload"
                                                                           :on-success="handleRoadSuccessProtocol"
                                                                           :action="actionUrl"
                                                                           name="filedata"
                                                                >
                                                                    <span v-if="road_protocol_fileUrl">
                                                                        <i style="color:#2CE136"
                                                                           class="el-icon-check">
                                                                        </i>
                                                                    </span>
                                                                    <el-button v-else icon="el-icon-picture-outline"
                                                                               class="img-button"
                                                                               circle></el-button>
                                                                    <div slot="tip" class="el-upload__tip">上传协议附件</div>
                                                                </el-upload>
                                                            </template>
                                                        </box>
                                                    </el-aside>
                                                </el-container>
                                            </template>
                                        </box>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" v-show="RegistForm.type.value == 10"
                                        :class="{'online_protocol':RegistForm.type.value == 10,'offline_protocol':RegistForm.type.value == 11}">
                                    <el-switch
                                            v-model="read"
                                            active-text="已阅读并同意注册协议">
                                    </el-switch>
                                </el-col>
                            </el-row>
                        </div>

                        <el-button v-show="active==2"
                                   :class="{'online_protocol_button': RegistForm.type.value == 10,'offline_protocol_button':RegistForm.type.value == 11,'offline_protocol_button_big':!isBig}"
                                   type="primary" @click="protocolSubmit"
                                   v-loading.fullscreen.lock="fullscreenLoading">提交协议
                        </el-button>
                    </el-form>
                </el-col>
            </el-row>
        </div>


        <el-dialog
                title="注册协议详情"
                :visible.sync="protocolContentVisible"
                :close-on-click-modal="false"
                :before-close="handleDialogClose"
                :append-to-body="true"
                width="800"
        >
            <div v-html="protocolContent"></div>
        </el-dialog>


        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%"
                :close-on-click-modal="false"
                :append-to-body="true"
                :before-close="handleDialogCloseSuccess">
            <span>你已经提交注册,请进入首页订阅产品！</span>
            <span slot="footer" class="dialog-footer">
            <!--&lt;!&ndash;<el-button @click="dialogVisible = false">取 消</el-button>-->
            <el-button type="primary" @click="goIndex">【前往订阅】</el-button>
          </span>
        </el-dialog>


    </div>
</template>


<script>

    import Box from '@/components/box/box.vue';

    import emitter from 'element-ui/src/mixins/emitter';
    import {mapActions, mapGetters} from 'vuex';
    import 'viewerjs/dist/viewer.css';
    import aEntityAPI from '@/api/aEntityAPI.js';
    import Viewer from 'v-viewer';
    import Vue from 'vue';

    import ls from '@/utils/ls.js'

    import userAPI from "@/api/userAPI.js";

    Vue.use(Viewer, {
        defaultOptions: {
            zIndex: 9999
        }
    });

    export default {
        components: {Box},
        props: ["saas"],
        mixins: [emitter],

        data() {
            var pwdPowerLengthFunc = (rule, value, callback) => {
                var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,16}');
                if (!pwdRegex.test(value)) {
                    callback(
                        new Error("请输入8位以上包含数字和大小写字母组成的密码")
                    );
                } else {
                    callback();
                }
            };
            let checkMobile = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("手机号不能为空"));
                }
                let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
                if (!re.test(value)) {
                    callback(new Error("请输入数字值"));
                } else {
                    if (value.length != 11) {
                        callback(new Error("手机号格式不正确"));
                    } else {
                        callback();
                    }
                }
            };
            //saas注册验证公司名称是否重复
            let checkEnterpriseName = (rule, value, callback) => {
                if(this.RegistForm.userType){
                    if (!value) {
                        callback(new Error("请填写企业名称"));
                    } else {

                        aEntityAPI.request('TenantInfoService', 'checkEnterpriseName', {
                            tenantInfoName: value,
                        }).then(response => {
                            if (response.data.success) {
                                var data = response.data.data;
                                if (!data.flag && data.platform != this.RegistForm.platform) {
                                    callback();
                                } else {
                                    callback(new Error("企业名称重复，请重新注册"));
                                }

                            }
                        });
                    }
                } else {
                    callback();
                }
            };
            let checkIdCardFile = (rule, value, callback) => {
                if (this.road_idcard_fileUrl == null) {
                    callback(new Error("请上传个人身份证"));
                } else {
                    callback();

                }

            };
            let checkEnterpriseFile = (rule, value, callback) => {
                if (this.road_no_fileUrl == null) {
                    callback(new Error("请上传营业执照"));
                } else {
                    callback();
                }

            };
            let checkAuthorizeIdcardFile = (rule, value, callback) => {
                if (this.road_authorize_idcard_fileUrl == null) {
                    callback(new Error("请上传法人身份证"));
                } else {
                    callback();
                }

            };
            let checkAuthorizeFile = (rule, value, callback) => {
                if (this.road_authorize_fileUrl == null) {
                    callback(new Error("请上传法人授权文件"));
                } else {
                    callback();
                }

            };
            let checkProtocolFile = (rule, value, callback) => {
                if (this.road_protocol_fileUrl == null) {
                    callback(new Error("请上传注册协议文件"));
                } else {
                    callback();
                }

            };
            let checkSmsCode = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("短信验证码不能为空"));
                }
                let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
                if (!re.test(value)) {
                    callback(new Error("请输入数字值"));
                } else {
                    callback();
                }
            };
            let checkDomain = (rule, value, callback) => {
                if (!this.RegistForm.domainType) {
                    callback();
                } else {
                    if (!value) {
                        callback(new Error("请输入需绑定的自有域名"));
                    }
                    let domain = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;

                    if (!domain.test(value)) {
                        callback(new Error("请输入正确域名"));
                    } else {
                        callback();
                    }
                }
            };

            return {
                showSkip:false,
                publicPath: process.env.VUE_APP_BASE_URL,
                active: 0,
                type: null,
                hrsaas: '',
                templateUrl: "",
                templateRegUrl: "",
                isIdCardFileSuccess: false,
                isNoFileSuccess: false,
                isAuthorizeIdCardFileSuccess: false,
                isAuthorizeFileSuccess: false,
                isProtocolFileSuccess: false,
                read: false,
                protocolContentVisible: false,
                dialogVisible: false,
                actionUrl: process.env.VUE_APP_BACKEND_URL_PROXY + '/file/upload',
                regSaasDialogVisible: false,
                params: {
                    thumb: true,
                    width: 326,
                    height: 200
                },
                road_idcard_fileUrl: null,
                road_idcard_file: null,
                road_no_fileUrl: null,
                road_no_file: null,
                road_authorize_idcard_fileUrl: null,
                road_authorize_idcard_file: null,
                road_authorize_fileUrl: null,
                download_road_authorize_file: null,
                road_authorize_file: null,
                road_protocol_fileUrl: null,
                road_protocol_file: null,
                road_fileUrl: '',
                checkEnterpriseName: null,
                road_file: null,
                road_thumbUrl: '',
                buttonTxt: "立即完善资料",
                agreement: "",
                ftpPath: "",
                fullscreenLoading: false,
                regist: true,
                success: false,
                procolVisible: false,
                procolListVisible: false,
                showPaswrd: true,
                imgCode: null,
                dialogImageUrl: '',
                filePath: '',
                dialogVisible: false,
                disabled: false,
                canModify: true,
                downTimeMessage:
                    "您的账户资料不完善，请完善您的账户资料。<br/> 系统将会在10秒后自动跳转至会员中心。",
                getSmsCodeName: "获取短信验证码",
                getSmsCodeStatus: false,
                nowTime: '',
                regMsg: '',
                isBig: false,
                exist: false,
                RegistForm: {
                    legalPersonIdCard: null,
                    registName: null,
                    mobile: null,
                    platform: 2,
                    registImgCode: null,
                    registCode: null,
                    registPassword: null,
                    idCard: null,
                    idName: null,
                    enterpriseName: null,
                    idCardFile: null,
                    enterpriseFile: null,
                    authorizeIdcardFile: null,
                    authorizeFile: null,
                    protocolFile: null,
                    typeName: null,
                    type: {
                        value: 10,
                    },
                    userType: true,//true企业，false个人
                    domainType: false,
                    domain: null,
                },
                products: [],
                rules: {
                    loginName: [
                        {
                            required: true,
                            message: "请输入用户名",
                            trigger: "change"
                        }
                    ],
                    registName: [
                        {
                            required: true,
                            message: "请输入用户名",
                            trigger: "blur"
                        }
                    ],
                    password: [
                        {
                            required: true,
                            message: "请输入密码",
                            trigger: "blur"
                        }
                    ],
                    mobile: [
                        {
                            required: true,
                            message: "请输入手机号",
                            trigger: "blur"
                        },
                        {validator: checkMobile, trigger: "blur"}
                    ],
                    registImgCode: [
                        {
                            required: true,
                            message: "请输入图片验证码",
                            trigger: "change"
                        }
                    ],
                    registCode: [
                        {
                            required: true,
                            message: "请输入短信验证码",
                            trigger: "change"
                        },
                        {validator: checkSmsCode, trigger: "blur"}
                    ],

                    domain: [
                        {validator: checkDomain, trigger: "change"}
                    ],


                    enterpriseName: [
                        // {
                        //     required: false,
                        //     message: "请输入企业名称",
                        //     trigger: "change"
                        // },
                        {validator: checkEnterpriseName, trigger: "blur"}
                    ],
                    idCardFile: [
                        {validator: checkIdCardFile, trigger: "change"}
                    ],
                    enterpriseFile: [
                        {validator: checkEnterpriseFile, trigger: "change"}
                    ],
                    authorizeIdcardFile: [
                        {validator: checkAuthorizeIdcardFile, trigger: "change"}
                    ],
                    authorizeFile: [
                        {validator: checkAuthorizeFile, trigger: "change"}
                    ],
                    protocolFile: [
                        {validator: checkProtocolFile, trigger: "change"}
                    ],

                    idName: [
                        {
                            required: true,
                            message: "请输入用户姓名",
                            trigger: "change"
                        },
                    ],
                    idCard: [
                        {
                            required: true,
                            message: "请输入身份证号",
                            trigger: "change"
                        },
                    ],
                    registPassword: [
                        {
                            required: true,
                            message: "请输入密码",
                            trigger: "change"
                        },
                        {validator: pwdPowerLengthFunc}
                    ]
                },
                isSaaS: true,
                protocolContent: null,

            };
        },

        computed: {
            ...mapGetters({
                shipper: 'user/shipper',
                sysConfig: 'config/sysConfig',
                authenticated: 'user/authenticated'
            }),

            disabledFile() {
                return this.active == 0 || this.active == 2
            }
        },

        created() {
        },

        methods: {

            userTypeChange(v){
                console.log('userTypeChange:' + v)
                if(!v){
                    this.RegistForm.enterpriseName = ''
                    this.$refs['registFormRef'].clearValidate('enterpriseName')
                }
            },

            goIndex() {
                this.dialogVisible = false;

                if (this.RegistForm.platform == 7) {
                    //工众人力saas用户调整
                    window.location.href = "hrsaas.html#/hrsaas";
                } else {
                    //saas用户调整
                    window.location.href = "saas.html#/saas";
                }
            },

            //下载授权书
            downloadAuthorizeFile() {
                if (this.RegistForm.platform == 2) {
                    window.location = '/template/reg/共生物流SaaS平台法人授权书.docx';
                }
                if (this.RegistForm.platform == 7) {
                    window.location = '/template/reg/工众人力SaaS平台法人授权书.docx';
                }
            },
            //下载用户服务协议
            downloadRegTemplate() {

                if (this.RegistForm.platform == 2) {
                    window.location = '/template/reg/共生物流SaaS平台用户服务协议.docx';
                }
                if (this.RegistForm.platform == 7) {
                    window.location = '/template/reg/工众人力SaaS平台用户服务协议.docx';
                }
            },

            handleDialogClose(done) {
                done();
            },
            handleDialogCloseSuccess() {
                if (this.RegistForm.platform == 7) {
                    //工众人力saas用户调整
                    window.location.href = "hrsaas.html#/hrsaas";
                } else {
                    //saas用户调整
                    window.location.href = "saas.html#/saas";
                }
            },

            handleRoadSuccessIdCardFile(res, file) {
                this.road_idcard_fileUrl = this.__ftpPath + res.data.filePath;
                this.road_idcard_file = res.data;
                this.ocr(this.road_idcard_file.id, 3, false, 3);

            },

            handleRoadSuccessEnterpriseNameFile(res, file) {
                this.road_no_fileUrl = this.__ftpPath + res.data.filePath;
                this.road_no_file = res.data;
                this.ocr(this.road_no_file.id, 5, false, 5);

            },

            handleRoadSuccessAuthorizeIdcardFile(res, file) {
                this.road_authorize_idcard_fileUrl = this.__ftpPath + res.data.filePath;
                this.road_authorize_idcard_file = res.data;
                this.ocr(this.road_authorize_idcard_file.id, 3, false, 4);

            },

            handleRoadSuccessAuthorizeFile(res, file) {
                this.road_authorize_fileUrl = this.__ftpPath + res.data.filePath;
                this.road_authorize_file = res.data;
            },

            handleRoadSuccessProtocol(res, file) {
                this.road_protocol_fileUrl = this.__ftpPath + res.data.filePath;
                this.road_protocol_file = res.data;
            },

            handleBeforeUpload(file) {
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    this.$message.error('上传头像图片大小不能超过 5MB!');
                    return false;
                }
                return true;
            },

            handleBeforeFileUpload(file) {
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isLt10M) {
                    this.$message.error('上传文件大小不能超过 10MB!');
                    return false;
                }
                return true;
            },

            ocr(fileId, type, autoRotate, fileType) {
                aEntityAPI
                    .request('OcrService', 'licOcr', {
                        fileId: fileId,
                        type: type,
                        autoRotate: autoRotate
                    })
                    .then(response => {
                        if (response.data.success) {

                            if (fileType == 5) {//公司
                                this.RegistForm.no = response.data.data.reg_num;
                            }
                            if (fileType == 3) {//个人
                                this.RegistForm.idCard = response.data.data.num;
                            }

                            if (fileType == 4) {//授权人
                                this.RegistForm.legalPersonIdCard = response.data.data.num;
                            }
                        }
                    });
            },

            registMobileChange(val) {
                if (val && val.length == 11) {
                    const params = {
                        mobile: val,
                    };

                    userAPI.checkMobileExist(params).then(response => {
                        if (response.status == null || response.status != 200) {
                            let message = response.data.message == null ? "" : response.data.message;
                            this.$message({
                                message: message,
                                type: "error"
                            });
                        } else {

                            let data = response.data;
                            if (data.isReged) {
                                //已注册其他产品用户
                                this.showPaswrd = false;
                                this.RegistForm.registPassword = "";
                                if (data.user.exist) {
                                    // this.regMsg = '';
                                }

                                if (data.user.exist != null) {
                                    this.exist = data.user.exist;
                                }

                                if (data.user.type != null && data.user.type == '个人') {
                                    this.RegistForm.registName = data.user.tenantInfoName;
                                }
                                if (data.user.type != null && data.user.type == '公司') {
                                    this.RegistForm.enterpriseName = data.user.tenantInfoName;
                                    this.RegistForm.registName = data.user.name;
                                }
                                if (data.user.type != null && data.user.platform == '人力') {
                                    this.regMsg = 'hrsaas.gsh56.com';
                                }
                                if (data.user.type != null && data.user.platform == '物流') {
                                    this.regMsg = 'saas.gsh56.com';
                                }
                                
                                if (data.user.type == undefined && data.user.platform == '人力') {
                                    this.regMsg = 'hrsaas.gsh56.com';
                                }
                                if (data.user.type == undefined && data.user.platform == '物流') {
                                    this.regMsg = 'saas.gsh56.com';
                                }

                            } else {
                                this.showPaswrd = true;
                            }
                        }
                    });
                }
            },

            //隐私协议查看
            lookProtocolPrivacy() {
                this.procolListVisible = false
                let params = {
                    type: 4,
                    code: 'privacy'
                };

                this.procolVisible = true;
                userAPI.getProductContract(params).then(res => {
                    if (res.data.success) {
                        this.agreement = res.data.data;
                    }
                });
            },

            lookProtocol(code) {
                this.procolListVisible = false
                let params = {
                    type: 3,
                    code: code

                };

                if (code != null) {
                    this.procolVisible = true;
                    if (params.code == 'zbrl') {
                        params.type = 0
                    }
                    userAPI.getProductContract(params).then(res => {
                        if (res.data.success) {
                            this.agreement = res.data.data;
                        }
                    });
                } else {
                    this.procolVisible = true;
                    userAPI.getPrcTransport(params).then(res => {
                        if (res.data.success) {
                            this.agreement = res.data.data;
                        }
                    });
                }

            },

            ...mapActions(["user/login", "user/checkLogin"]),

            backLogin() {
                if (this.isSaaS) {
                    window.location.href = "login.html#/login/saas";
                    window.location.reload();
                } else {
                    window.location.href = "login.html#/login";
                }
            },

            //获取图片验证码
            changeImgCode() {
                this.imgCode =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    process.env.VUE_APP_BACKEND_URL_PROXY +
                    "/verify?randid=" +
                    Math.abs(Math.sin(new Date().getTime()));
            },

            //获取短信验证码
            getSmsCode(ref) {
                let valid = true;
                this.$refs[ref].clearValidate();
                this.$refs[ref].validateField(["mobile", "registImgCode"], err => {
                    if (err != "") {
                        valid = false;
                    }
                });

                if (valid) {
                    
                    var type = '';

                    if(!this.showPaswrd){
                        if (this.RegistForm.platform == 7) {
                            type = 'hrsaas';
                        } else if (this.RegistForm.platform == 2) {
                            type = 'saas';
                        }
                    }else{
                        if (this.RegistForm.platform == 7) {
                            type = 'hrsaas_shipper_register';
                        } else if (this.RegistForm.platform == 2) {
                            type = 'saas_shipper_register';
                        }
                    }

                   
                    let params = {
                        userImgVerifyCode: this.RegistForm.registImgCode,
                        mobile: this.RegistForm.mobile,
                        type: type
                    };

                    if(!this.showPaswrd){
                        params = {
                            mobile: this.RegistForm.mobile,
                            productKey: type
                        }
                      var interval = this.waitCodeTime();
                        this.getRegSmsCode(params)
                    } else {
                        //直接等待
                        var interval = this.waitCodeTime();
                        userAPI.getSmsVerifyCode(params).then(response => {
                            if (response.status != null && response.status == 200) {
                                
                            } else {
                                let message =
                                    response.data.message == null ? "获取验证码失败" : response.data.message;
                                if(message.indexOf('图片验证码') > -1){
                                    this.stopCodeTime(interval,"获取短信验证码")
                                }
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                            }
                        });
                    }


                }
            },

            //如果已经注册过的用户（获取验证码数据）
            getRegSmsCode(params) {
                userAPI.getSmsCode(params).then(response => {
                    if (response.data.success) {
                    } else {
                        let message =
                            response.data.msg == null ? "获取验证码失败" : response.data.msg;
                        this.$message({
                            message: message,
                            type: "error"
                        });
                    }
                });
            },


            verify() {

                if (this.filePath == null || this.filePath == '') {
                    this.$message.error('请上传证件');
                    return false
                }
                return true;
            },

            //注册按钮提交
            registBtnSubmit() {
                this.$refs.registFormRef.validate(valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        let params = {
                            mobile: this.RegistForm.mobile,
                            registName: this.RegistForm.registName,
                            domain: this.RegistForm.domainType ? this.RegistForm.domain : null,
                            smsCode: this.RegistForm.registCode,
                            password: this.RegistForm.registPassword,
                            platform: this.RegistForm.platform,
                            registImgCode: this.RegistForm.registImgCode,
                            isSmsCode:true,
                            enterpriseName: this.RegistForm.enterpriseName ? this.RegistForm.enterpriseName : null,
                        };
                        userAPI.registSaasSubMit(params).then(response => {
                            this.fullscreenLoading = false;
                            if (response.data.success == true) {
                                this.userLogin()

                                //this.$message({
                                  //  message: '账户提交成功',
                                 //type: 'success'
                                //});
                            } else {
                                let message =
                                    response.data.message == null ? "注册失败" : response.data.message;
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },

            handleRoadSuccess() {

            },

            //提交授权文件
            fileSubmit() {
                this.$refs.registFormFileRef.validate(valid => {

                    if (valid) {
                        this.fullscreenLoading = true;
                        let params = {
                            mobile: this.RegistForm.mobile,
                            platform: this.RegistForm.platform,
                            legalPersonIdCard: this.RegistForm.legalPersonIdCard,
                            idCard: this.RegistForm.idCard,
                            no: this.RegistForm.no,
                            idCardFileId: this.road_idcard_file ? this.road_idcard_file.id : null,
                            noFileId: this.road_no_file ? this.road_no_file.id : null,
                            authorizeIdcardFileId: this.road_authorize_idcard_file ? this.road_authorize_idcard_file.id : null,
                            authorizeFileId: this.road_authorize_file ? this.road_authorize_file.id : null,
                        };
                        userAPI.fileSaaSSubmit(params).then(response => {
                            this.fullscreenLoading = false;
                            if (response.status != null && response.status == 200) {
                                this.active = 2;
                                this.getCurrentTenantInfo();

                               this.protocolPreview();


                                this.$message({
                                    message: '授权文件提交成功',
                                    type: 'success'
                                });

                            } else {
                                let message =
                                    response.data.message == null ? "注册失败" : response.data.message;
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },

            //提交注册协议文件
            protocolSubmit() {

                if (this.RegistForm.type.value == 10 && !this.read) {
                    this.$message.error('请同意注册协议');
                    return false
                }
                if (this.RegistForm.type.value == 11 && (this.road_protocol_file == null || this.road_protocol_file == '')) {
                    this.$message.error('请上传协议附件');
                    return false
                }

                this.$refs.registFormProtocolRef.validate(valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        let params = {
                            mobile: this.RegistForm.mobile,
                            type: this.RegistForm.type.value,
                            platform: this.RegistForm.platform,
                            protocolFileId: this.road_protocol_file ? this.road_protocol_file.id : null,
                        };
                        userAPI.protocolSaaSSubmit(params).then(response => {
                            this.fullscreenLoading = false;
                            this.dialogVisible = true;
                            if (response.status != null && response.status == 200) {
                                this.active = 3
                                this.$message({
                                    message: '协议文件提交成功',
                                    type: 'success'
                                });
                                this.getCurrentTenantInfo();
                            } else {
                                let message =
                                    response.data.message == null ? "注册失败" : response.data.message;
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                            }
                        });
                    }
                });
            },

            protocolPreview(flag) {
                if (flag) {
                    this.protocolContentVisible = true;
                }
                let templateCode = null;
                if (this.RegistForm.platform == 2) {
                    templateCode = 'saas_reg_contract';
                }
                if (this.RegistForm.platform == 7) {
                    templateCode = 'saas_reg_contract_hr';
                }
                let year = new Date().getFullYear();
                let month = new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
                let date = new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
                let timeFormate = year + "年" + month + "月" + date + "日";
                let data = {
                    tenantInfoName: (this.RegistForm.enterpriseName == null ||  this.RegistForm.enterpriseName == '')  ? this.RegistForm.registName : this.RegistForm.enterpriseName,
                    userName: this.RegistForm.registName,
                    signDate: timeFormate
                }
                userAPI.protocolSaaSPreview(templateCode, JSON.stringify(data)).then(response => {
                    if (response.data.success) {
                        this.protocolContent = response.data.data
                    }
                });
                ;
            },

            //短信验证码倒计时
            waitCodeTime() {
                let waitSeconds = 60;
                this.getSmsCodeStatus = true;
                var interval = setInterval(() => {
                    waitSeconds--;
                    // this.getSmsCodeName = "校验码已发送，请等待" + waitSeconds + "秒重试";
                    this.getSmsCodeName = waitSeconds + "秒后重新获取";
                    this.getSmsCodeStatus = true;
                    if (waitSeconds < 1) {
                       this.stopCodeTime(interval)
                    }
                }, 1000);
                return interval;
            },

            stopCodeTime(interval,btnTxt){
                this.getSmsCodeName = btnTxt || "没收到验证码？重新获取";
                this.getSmsCodeStatus = false;
                clearInterval(interval);
            },

            //跳转到个人信息
            goToUserInfo() {
                this["user/login"]({
                    loginName: this.RegistForm.mobile,
                    password: this.RegistForm.registPassword
                }).then(data => {
                    if (data.success) {
                        //saas用户调整
                        window.location.href = "common.html#/home";

                    }
                });
            },


            userLogin() {
                var params = {
                    loginName: this.RegistForm.mobile,
                    password: this.RegistForm.registPassword
                };

                if(!this.showPaswrd){
                    params = {
                        loginName: this.RegistForm.mobile,
                        productKey: this.RegistForm.platform == 7 ? "hrsaas" : "saas",
                        smsCode: this.RegistForm.registCode,
                        verify: this.RegistForm.registImgCode,
                    };
                }

                this["user/login"](params).then(res => {
                    if (res.success) {
                         this.$message({
                               message: '账户提交成功',
                                 type: 'success'
                                });
                        this.active = 1;
                        this.getCurrentTenantInfo();
                        this.protocolPreview();
                    }else{
                        this.$message({
                                    message: res.msg,
                                    type: "error"
                                });
                    }
                });
            },

            getCurrentTenantInfo() {
                let params = {
                    platform: this.RegistForm.platform,
                };
                userAPI.getCurrentSaaSTenantInfo(params).then(response => {

                    if (response.data.success && response.data.data.info != null) {
                        var data_ = response.data.data;
                        data_.licList.forEach(item => {
                            if (item.type == '个人身份证') {
                                this.road_idcard_fileUrl = item.file == null ? null : this.__ftpPath + item.file.filePath
                                this.RegistForm.registName = item.idName == "" ? null : item.idName;
                                this.isIdCardFileSuccess = item.status == "无效" ? true : false;
                            }
                            if (item.type == '公司营业执照') {
                                this.road_no_fileUrl = item.file == null ? null : this.__ftpPath + item.file.filePath
                                this.RegistForm.enterpriseName = item.enterpriseName == "" ? null : item.enterpriseName;
                                this.RegistForm.registName = item.idName == "" ? null : item.idName;
                                this.isNoFileSuccess = item.status == "无效" ? true : false;
                            }
                            if (item.type == '授权人身份证') {
                                this.road_authorize_idcard_fileUrl = item.file == null ? null : this.__ftpPath + item.file.filePath
                                this.isAuthorizeIdCardFileSuccess = item.status == "无效" ? true : false;
                            }
                            if (item.type == '法人授权') {
                                this.road_authorize_fileUrl = item.file == null ? null : this.__ftpPath + item.file.filePath
                                this.isAuthorizeFileSuccess = item.status == "无效" ? true : false;
                            }
                            if (item.type == '注册协议附件') {
                                this.road_protocol_fileUrl = item.file == null ? null : this.__ftpPath + item.file.filePath
                                this.RegistForm.type.value = 11;
                                this.isProtocolFileSuccess = item.status == "无效" ? true : false;
                            }
                            if (item.type == '在线服务协议') {
                                this.RegistForm.type.value = 10;
                            }
                        })

                        this.RegistForm.mobile = data_.info.mobile;
                        this.RegistForm.typeName = data_.info.type;
                        this.RegistForm.domain = data_.info.domain ? data_.info.domain : null;
                        this.RegistForm.registName = data_.user.realName;

                        ls.set('tenantInfo', data_.info)

                        if (data_.info.platform == '人力') {
                            this.RegistForm.platform = 7;
                            this.hrsaas = '工众人力'
                        }


                        if (data_.info.type == '公司') {
                            this.RegistForm.enterpriseName = data_.info.name//企业名称
                        }

                        this.protocolPreview();

                        if (data_.info != null && (data_.info.status == '已提交帐号')) {
                            this.active = 1;
                        }
                        if (data_.info != null && (data_.info.status == '已提交授权')) {
                            this.active = 2;
                            this.read = false;
                        }
                        if (data_.info != null && (data_.info.status == '已提交协议')) {
                            this.dialogVisible = true
                            this.active = 3;
                            this.read = true;
                        }
                    }
                });
            },

            timeFormate() {
                let year = new Date().getFullYear();
                let month = new Date().getMonth() + 1 < 10 ? "0" + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
                let date = new Date().getDate() < 10 ? "0" + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
                return year + "年" + month + "月" + date + "日";
            },
            toSkip(){
                this.active = 2
            }
        },

        mounted() {

            var serverHost = window.location.host;
            if(serverHost.indexOf("hrsaas.") > -1){
                this.showSkip = true
            }
            if (serverHost.indexOf("hrsaas.") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
                this.RegistForm.platform = 7;
                this.hrsaas = '工众人力'
            }

            this.getCurrentTenantInfo();

            this.protocolPreview();

            userAPI.reqBeforeVerify().then(response => {
                if (response.status != null && response.status == 200) {
                    this.changeImgCode()
                } else {
                    let message =
                        response.data.message == null ? "failed" : response.data.message;
                    this.$message({
                        message: message,
                        type: "error"
                    });
                }
            });


            var windowWidth = document.documentElement.clientWidth
            if (windowWidth > 1366) {
                this.isBig = true
            }


        }
    }
</script>

<style>

    .login-form .el-form-item {
        margin-bottom: 0px;
    }

    .el-step .el-step__title {
        margin-left: -20px;
    }

    #el-upload {
        width: 220px;
    }

    .input-with-enumselector .el-input-group__append {
        padding: 0px;
    }

    .money_coupon_date {
        margin-right: 10px;
    }

    .money_coupon_input {
        width: 188px;
        margin-right: 10px;
    }

    .form-input {
        width: 450px;
        height: 320px;
        margin-left: 9%;
        float: left
    }

    .form-image-code {
        width: 100%;
        height: 32px;
        cursor: pointer;
    }

    .company-uploader .el-upload--picture-card {
        width: 110px;
        height: 67px;
        line-height: 83px;
        position: relative;
        overflow: hidden;
        /*border: 1px dashed red;*/

    }

    .company-uploader .el-button--medium.is-circle {
        padding: 3px;
    }

    .company-avatar {
        width: 120px;
        height: 65px;
        display: block;
        object-fit: contain;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    /*.gs-box {*/
    /*margin-top: -20px;*/
    /*}*/

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
        object-fit: cover;
    }

    .upload-button {
        text-align: center;
        font-size: 15px;
        width: 100px;
    }

    .finger {
        transform: rotate(270deg);
        color: #409eff;
        font-weight: bold;
        padding: 2px;
    }

    .company-alert {
        margin: 0 auto 15px auto;
        width: 800px
    }

    .warning-span {
        color: #E6A23C;
    }

    .zjgq {
        position: absolute;
        bottom: 0;
        height: 50px;
        width: 328px;
        color: #E6A23C;
        line-height: 50px;
        background: rgba(0, 0, 0, 0.7);
        font-size: 16px;
    }

    .require-span {
        color: #F56C6C;
        vertical-align: middle;
    }

    .small-span {
        font-size: 12px;
    }

    .arrow {
        position: absolute;
        right: -50px;
        top: 70px;
    }

    .info-img {
        border-right: 1px solid #DCDCDC
    }

    .info-msg {
    }

    .saas_reg_border {
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: space-around;*/
        /*align-items: center;*/
        padding-left: 50px;
        padding-right: 50px;
    }

    .reg_form_input {
        /*width: 366px;*/
        margin-right: 34px;
        margin-top: 10px;
    }

    .reg_form_protocol {
        /*width: 366px;*/
        margin-top: 12px;

    }

    .reg_form_protocol_file {
        width: 400px;
        margin-top: 0px;
    }

    .reg_form_protocol .protocol_content {
        min-height: 180px;
    }

    .reg_form_protocol .online_protocol {

    }

    .reg_form_protocol .offline_protocol {
        /*min-height: 80px;*/

        margin-left: 40px;
    }

    .login-form {
        height: 450px;
        margin-top: 20px;
    }

    .login-form .online_protocol_button {
        width: 100%;
        margin-top: 30px;
        border-radius: 4px;
    }

    .login-form .offline_protocol_button {
        width: 100%;
        margin-top: 30px;
        border-radius: 4px
    }

    .reg_form_file {
        /*width: 366px;*/
        margin-left: 17px;
        margin-right: 17px;
    }

    .reg_form_file .reg_form_file_button {
        width: 100%;
        margin-top: 40px;
        border-radius: 4px
    }

    .reg_form_file .reg_form_file_button_null {
        width: 100%;
        /*margin-top: 5px;*/
        border-radius: 4px
    }

    div.el-input-group__prepend {
        padding: 0px 10px;
    }

    .form-sms-code {
        width: 100%;
    }

    .reg_form_protocol .protocol_content_see {
        color: #006ce7;
    }

    .reg_form_protocol .protocol_content_see:hover {
        cursor: pointer;
    }

    .protocol_preview_content {
        height: 250px;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: 12px;
    }

    .protocol_preview_content::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }

    .protocol_preview_content::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #b3b0b0;
    }

    .protocol_preview_content::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background: #ededed;
    }

    .reg_form_input_cover_up {
        position: fixed;
        top: 342px;
        width: 42%;
        z-index: 9;
        opacity: 0.5;
        height: 48%;
        margin-left: 24%;
        background-color: #8c939d;
    }

    .reg_form_file_cover_up {
        position: fixed;
        top: 342px;
        width: 450px;
        z-index: 9;
        opacity: 0.5;
        height: 350px;
        margin-left: 20px;
        background-color: #8c939d;
    }

    .reg_form_protocol_cover_up {
        position: fixed;
        top: 35%;
        width: 42%;
        z-index: 9;
        opacity: 0.5;
        height: 48%;
        margin-left: 20px;
        background-color: #8c939d;
    }

    .company-uploader .el-upload__tip {
        margin-top: -5px;
        color: #589CFD;
    }

    .company-uploader .legal_person_template:hover {
        cursor: pointer
    }

    .id_card_file_border .el-upload--picture-card {
        border: 1px dashed red;
    }

    .id_card_file_border .el-upload--picture-card:hover {
        border: 1px dashed red;
    }

    .no_file_border .el-upload--picture-card {
        border: 1px dashed red;
    }

    .no_file_border .el-upload--picture-card:hover {
        border: 1px dashed red;
    }

    .authorize_idcard_file_border .el-upload--picture-card {
        border: 1px dashed red;
    }

    .authorize_idcard_file_border .el-upload--picture-card:hover {
        border: 1px dashed red;
    }

    .authorize_file_border .el-upload--picture-card {
        border: 1px dashed red;
    }

    .authorize_file_border .el-upload--picture-card:hover {
        border: 1px dashed red;
    }

    .protocol_file_border .el-upload--picture-card {
        border: 1px dashed red;
    }

    .protocol_file_border .el-upload--picture-card:hover {
        border: 1px dashed red;
    }

    .reg_form_file .el-form-item__error {
        margin-left: 45px;
        margin-top: -25px;
    }

    .reg_form_protocol .el-form-item__error {
        margin-left: 45px;
        margin-top: -25px;
    }

    .download_border {
        display: flex;
        margin-top: 40px;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 110px;
        height: 67px;
        border: 1px dashed #c0ccda;
        border-radius: 6px;
        background-color: #FBFDFF;
    }

    .download_border .el-icon-download {
        font-size: 28px;
    }

    .download_border .el-button--medium.is-circle {
        padding: 3px;
    }

    .download_border .el-upload-tip {
        color: #8c939d;
    }

    .el-upload-tip-disabled {
        color: #8c939d;
    }

    .download_template_file {
        color: #589CFD;
        margin-left: 6px;
        margin-top: -5px;
    }

    .download_template_file_disabled {
        color: #8c939d;
        margin-left: 6px;
        margin-top: -5px;
    }

    .protocol_preview_content_disabled {
        opacity: 0.5;
    }

    .offline_protocol_content_big {
        margin-left: 115px;
    }

    .offline_protocol_content_min {
        margin-left: 40px;
    }

    .download_template_file_border .el-form-item__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .login-form .offline_protocol_button_big {
        margin-left: 33px;
    }

</style>
