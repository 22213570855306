import Vue from 'vue'
import Login from './LoginApp.vue'
import router from './loginRoute.js'
import store from '@/store'
import ElButton from '@/components/rewrite-ele/button.vue';
import ElementUI from 'element-ui';
import http from '@/plugins/http/http'

import '@/style/theme/index.css'
import '@/style/index.scss';
import ls from '@/utils/ls'
import '@/utils/xmlHttpRequestHook.js'

Vue.use(ElementUI, {
    size: 'medium', // medium / small / mini
}); // size用于改变组件的默认尺寸，zIndex 设置弹框的初始 z-index（默认值：2000）

Vue.config.productionTip = false
const HOST = window.location.protocol + '//' + window.location.host
Vue.use(http, {
    baseURL: HOST + process.env.VUE_APP_BACKEND_URL_PROXY
})

Vue.component('ElButton', ElButton); //重写 按钮组件、支持记录按钮点击
//是否开启 日志记录
Vue.prototype._LOGGGER = false

Vue.prototype.__ftpPath =  window.location.protocol + '//' + window.location.host + process.env.VUE_APP_FTP_PATH;

window.__GetReleaseInfo = function () {
    console.info("release mode: " + process.env.VUE_APP_MODE)
    console.info("release version: " + process.env.VUE_APP_VERSION)
    console.info("relase time: " + process.env.VUE_APP_RELEASE_TIME)
    return true
}

router.beforeEach((to, from, next) => {

    const tenantInfo = ls.get('tenantInfo');

    const lg = ls.get('login_info');
    //const user = ls.get('userInfo');
    const menu = ls.get('menu')
    //const shipper = ls.get('shipper')
    if(to.fullPath.indexOf("loading") > -1){
        next();
        return;
    }
    if(to.fullPath.indexOf("regSaas") > -1 ){        
        next()
        return
    } else {

        var saas = false
        if (window.location.host.indexOf("saas.") > -1)
            saas = true;

        // debugger
        if(saas && tenantInfo != null && tenantInfo.status!=null
            && (
                (typeof tenantInfo.status =='object' && [-7,-5].indexOf(tenantInfo.status.value) > -1 ) ||
                (typeof tenantInfo.status =='string' && ['已提交帐号','已提交授权'].indexOf(tenantInfo.status) > -1 )
            )
        ){
           //saas注册用户的tenantInfo的状态还在待审核阶段，需转到到saas注册页面
        //    next({
        //         path: '/regSaas'
        //     })
        //     return;

        next()
        return
       }

        if (menu) { //已登录
            if (menu.length == 1) {
                //一个系统菜单，只进这个系统
                const url = menu[0].url;
                window.location.href = url;
            } else if (menu.length > 1) {
                //多个系统菜单
                let manageFlag= false;
                for(var i=0;i<menu.length;i++){
                    if(menu[i].mxtype=='manage'){
                    manageFlag = true;
                    }
                }

                //多个系统菜单
                if (manageFlag) {
                window.location.href = "manage.html#/home";
                } else {
                const url = menu[0].url
                window.location.href = url
                }
            } else {
                Vue.prototype.$alert("无菜单权限，将返回登录", "登录失败", {
                    confirmButtonText: "确定",
                    callback: action => {
                        store.dispatch('user/logout').then(() => {
                            window.location.reload()
                            //store._vm.$router.replace('/login')
                        });
                    }
                });
            }
        } else {
            if (lg) { //首次登录
                if (to.fullPath === '/resetPass') {
                    next();
                } else {
                    next({
                        path: '/resetPass'
                    })
                }
            } else { //未登录
                if (to.fullPath === '/resetPass') {
                    next({
                        path: '/login'
                    })
                } else {
                    next();
                }
            }
        }
    }

})

router.afterEach((to) => {
    window.document.title = to.meta.title

})

new Vue({
    router,
    store,
    render: h => h(Login)
}).$mount('#app')



String.prototype.replaceAll = function(s1, s2) {
    return this.replace(new RegExp(s1, "gm"), s2);
}