<template>
    <div style="width:100%;height:100%;position: relative;">

        <el-form ref="loginFormRef" :model="LoginForm" class="login-form" :rules="rules">
            <div class="login-title">
                <!--<h3 v-if="hrsaas">{{loginRole}}登录</h3>-->
                <!--<h3 v-else-if="isSaaS">{{loginRole}}系统登录</h3>-->
                <h3>用户登录</h3>
            </div>

            <span v-if="isQrcode && isSaaS == true">
                <div style="text-align: center;" v-if="qrExpire" @click="refreshQrCode">
                    <div class="mask" style="text-align: center;display:flex;align-items: center;justify-content: center;">
                        <img src="@/assets/login/shuaxin.png" style="width:50px;height:50px">
                    </div>
                    <canvas ref="canvasRef" class="qrcode" />

                    <div style="text-align: center;">二维码已过期，请点击刷新二维码！</div>
                </div>
                <div style="text-align: center;display:flex;align-items: center;justify-content: center;width:100%;height:200px"
                    v-if="qrLoginLock">
                    <div style="text-align: center;">扫码登录中请稍后!</div>
                    <!-- <div class="qrcode" style=""></div> -->
                </div>
                <div style="text-align: center;display:flex;align-items: center;justify-content: center;width:100%;height:200px"
                    v-if="qrLoginerror">
                    <div style="text-align: center;">二维码登录环境错误,请使用密码登录!</div>
                    <!-- <div class="qrcode" style=""></div> -->
                </div>
                <div style="text-align: center;" v-if="!qrLoginerror && !qrLoginLock && !qrExpire">
                    <canvas ref="canvasRef" class="qrcode" />
                    <div style="text-align: center;">请使用56SaaS客户端扫码登录!</div>
                </div>
            </span>
            <span v-else>
                <el-form-item prop="loginName">
                    <el-input ref="loginName" type="input" placeholder="请输入登录名或手机号" v-model="LoginForm.loginName"
                        autocomplete="off" maxlength="20" v-on:keyup.enter.native="login" @change="loginNameChange">
                        <template slot="prepend">
                            <i class="el-icon-user" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password" style="margin-top: 22px">
                    <el-input type="password" placeholder="请输入登录密码" v-model.trim="LoginForm.password" autocomplete="off"
                        maxlength="20" v-on:keyup.enter.native="login" show-password>
                        <template slot="prepend">
                            <i class="el-icon-lock" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item v-if="adminDcVerify" prop="smsCode" style="margin-top: 22px">
                    <el-row type="flex" align="middle">
                        <el-col :span="10">
                            <el-input type="input" placeholder="短信验证码" v-model.trim="LoginForm.smsCode" maxlength="6"
                                autocomplete="off"></el-input>
                        </el-col>
                        <el-col :span="14">
                            <el-button type="primary" plain :disabled="this.getSmsCodeStatus" style="width:100%;height:47px"
                                @click="getSmsCode('loginFormRef')">{{ this.getSmsCodeName }}
                            </el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item v-if="showVerify" prop="verify" style="margin-top: 22px">
                    <el-row>
                        <el-col :span="16">
                            <el-input type="input" placeholder="图片验证码（区分大小写）" v-model="LoginForm.verify"
                                autocomplete="off"></el-input>
                        </el-col>
                        <el-col :span="8" style="height:47px">
                            <!-- <span style="height:47px"> -->
                            <el-image @click="changeImgCode" fit="fill" :src="verifyUrl"
                                style="width: 100%;height:47px;cursor: pointer;float:right" />
                            <!-- </span> -->
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-button type="text" style="margin:0;margin-bottom:12px;float:right" @click="forgotPass">忘记密码？</el-button>
                <br />
                <el-button type="primary" style="width:100%;margin-bottom:10px;" @click="login"
                    v-loading.fullscreen.lock="fullscreenLoading">登 录
                </el-button>



                <el-divider class="login-divider"></el-divider>
                <!--<el-button type="text" @click="registBtn">立即注册{{loginRoleName}}</el-button>-->
                <el-button type="text" v-if="!isZbrl" @click="registBtn">立即注册</el-button>

            </span>

            <!-- <el-button type="text" @click="qrcodeLogin" v-if="!isQrcode">扫码登录</el-button>
        <el-button type="text" @click="qrcodeLogin" v-else>密码登录</el-button> -->
        </el-form>
        <div class="rightBottomCss" v-if="isSaaS == true">

            <div class="bubble-wrap" v-if="!isQrcode">
                <div class="bubble-arrow">
                    <span class="span1Css"></span>
                    <span class="span2Css"></span>
                </div>
                APP扫码登录
            </div>
            <div class="bubble-wrap" v-if="isQrcode">
                <div class="bubble-arrow">
                    <span class="span1Css"></span>
                    <span class="span2Css"></span>
                </div>
                密码登录
            </div>

            <div class="wrapper">
                <div class="triangle1" v-if="!isQrcode"></div>
                <img src="@/assets/login/erweima.png" class="img1CSS" v-if="!isQrcode" @click="qrcodeLogin">

                <div class="triangle2" v-if="isQrcode"></div>
                <img src="@/assets/login/diannao.png" class="img2CSS" v-if="isQrcode" @click="qrcodeLogin">
            </div>

        </div>

        <el-dialog
            title="选择工厂机构"
            :visible.sync="organDialogVisible"
            width="700px"
            :append-to-body="true"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
        >
            <select-organ-list :organList="organList" @selectOrgan="selectOrgan" ref="organListRef"></select-organ-list>
            <div slot="footer" class="dialog-footer">
                <el-button @click="organDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmOrgan" :loading="confirmOrganLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import ls from "@/utils/ls";
import userAPI from "@/api/userAPI.js";
import aEntityAPI from "@/api/sysAPI.js";
import aes from './aes.js';
import QRCode from 'qrcode';
import io from "../components/socket.io";
import selectOrganList from "./selectOrganList.vue";

export default {
    components: {
        selectOrganList
    },
    props: ["mobile", "saas", "fromUrl"],
    data() {
        return {
            timer: null,
            qrExpire: false,
            qrLoginLock: false,
            qrLoginerror: false,
            loginWebSocket: null,
            isQrcode: false,
            qrcode: null,
            uuid: null,

            isZbrl: false,
            fullscreenLoading: false,
            hrsaas: false,

            adminDcVerify: false, //等保针对管理员，double check sms verify
            getSmsCodeName: "获取短信验证码",
            getSmsCodeStatus: false,

            LoginForm: {
                loginName: "",
                password: "",
                smsCode: "",
                verify: ""
            },
            showVerify: false,
            verifyUrl: window.location.protocol + "//" + window.location.host + process.env.VUE_APP_BACKEND_URL_PROXY + "/verify?randid=" + Math.abs(Math.sin(new Date().getTime())),
            rules: {
                loginName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "change"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "change"
                    }
                ],
                smsCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "change"
                    }
                ],
                verify: [
                    {
                        required: true,
                        message: "请输入图片验证码",
                        trigger: "change"
                    }
                ]
            },
            loginRole: '',
            loginRoleName: '',
            isSaaS: false,
            organDialogVisible: false,
            organList:[],
            currentOrgan:null,
            confirmOrganLoading:false,
        };
    },
    computed: {
        ...mapGetters({
            userinfo: "user/userinfo",
            sysConfig: "config/sysConfig",
            shipper: "user/shipper",
            __menu: "user/menu"
        })
    },

    created() {
        if (ls.get("LoginError") != null && ls.get("LoginError") == 1) {
            this.showVerify = true
        }
        this.initSocket();
    },

    methods: {
        ...mapActions(["user/login", "user/logout", "user/checkLogin","user/refreshShipperAndOrgan"]),


        refreshQrCode() {
            this.qrLoginerror = false;
            this.qrLoginLock = false,
                this.isQrcode = true;
            this.qrcode = null;
            this.uuid = null;
            this.qrExpire = false;
            clearTimeout(this.timer);

            this.getQrcode();
        },

        qrcodeLogin() {
            if (this.isQrcode) {
                this.qrLoginerror = false;
                this.qrLoginLock = false,
                    this.isQrcode = false;
                this.qrcode = null;
                this.uuid = null;
                this.qrExpire = false;
                clearTimeout(this.timer);
            } else {
                this.getQrcode();
            }

        },
        getQrcode() {
            this.isQrcode = true;

            //开启扫码登录
            if (this.loginWebSocket == null || this.loginWebSocket.id == null) {
                this.qrLoginerror = true;
                return;
            } else {
                this.qrLoginerror = false;
            }

            var loginDto = {
                sourcePlatform: "saasPc",
                targetEmpowerPlatform: "saasApp",
                sourceSocketId: this.loginWebSocket.id
            }

            userAPI.getLoginQrCode(aes.encrypt(JSON.stringify(loginDto))).then(response => {
                if (response.status != null && response.status == 200) {

                    this.$nextTick(() => {
                        QRCode.toCanvas(this.$refs.canvasRef, response.data.qrCode);
                    });
                    this.qrcode = response.data.qrCode;
                    this.uuid = response.data.uuid;

                    this.timer = setTimeout(() => {
                        this.qrExpire = true;
                    }, 2 * 60 * 1000);

                } else {
                    this.$nextTick(() => {
                        QRCode.toCanvas(this.$refs.canvasRef, "获取登录二维码错误请重试!");
                    });
                }
            }).catch(() => {
              this.$nextTick(() => {
                QRCode.toCanvas(this.$refs.canvasRef, "获取登录二维码错误请重试!");
            });
            });



        },

        //二维码登录socket ---start-----
        initSocket() {

            let that = this;
            let opts = {
                transports: ["websocket"],
            };

           let wsUrl = "";
            // let defUrl = "http://192.168.20.234:3000"
            // let defUrl = "http://my.gsh56.com:3003";

            if (process.env.VUE_APP_MODE === "PRO") {
                wsUrl = window.location.protocol + "//" + window.location.hostname + ":3001";
            } else if (process.env.VUE_APP_MODE === "TEST") {
                // wsUrl = window.location.protocol + "//" + window.location.hostname + ":3003";
                    // wsUrl = "http://saas.gsh56test.com" + ":3003";
                    wsUrl =  "https://saas.gsh56test.com" + ":3005";
            } else {
                //开发环境
                wsUrl = window.location.protocol + "//" + window.location.hostname + ":3003";
            }
            console.log("aaaaaaaaaaa")
            console.log(wsUrl)

            that.loginWebSocket = io.connect(wsUrl, opts);
            that.loginWebSocket.on("connection", function (obj) {
                console.log("connection:" + obj);
            });
            that.loginWebSocket.on("webPush", function (obj) {
                console.log("webPush:" + obj);
            });
            that.loginWebSocket.on("lockLoginQr", obj => {
                console.log("lockLoginQr:" + obj);

                if (that.uuid == null) {
                    return;
                }
                if (that.loginWebSocket.id == null) {
                    return;
                }
                let result = JSON.parse(obj)

                if (aes.encrypt(that.uuid) == result.uuid && aes.encrypt(that.loginWebSocket.id) == result.sourceSocketId) {
                    that.qrLoginLock = true;
                    console.log("扫码登录中请稍后！")
                }

            });
            that.loginWebSocket.on("loginQrCode", obj => {
                console.log("loginQrCode:" + obj);

                if (that.uuid == null) {
                    return;
                }
                if (that.loginWebSocket.id == null) {
                    return;
                }

                let result = JSON.parse(obj);
                console.log("aes.encrypt(that.uuid)：" + aes.encrypt(that.uuid))
                console.log("result.tocketUuid：" + result.tocketUuid)
                console.log("aes.encrypt(that.loginWebSocket.id)：" + aes.encrypt(that.loginWebSocket.id))
                console.log("result.sourceSocketId" + result.sourceSocketId)
                if (aes.encrypt(that.uuid) == result.tocketUuid && aes.encrypt(that.loginWebSocket.id) == result.sourceSocketId) {
                    this.qrLogin(result);
                }



                // if(aes.encrypt(that.uuid) == result.uuid){
                //     that.qrLoginLock = true;
                //     console.log("扫码登录中请稍后！")
                // }

            });

        },
        //二维码登录socket ---end-----


        loginNameChange(v) {

            /*检查是否需要图片验证码*/
            userAPI.needVerify(v).then(response => {
                //双要素验证
                if (response.status != null && response.status == 200) {
                    if (response.data.needVerify) {
                        this.showVerify = true
                    }
                }
            });

            //需要双要速验证
            if (this.sysConfig.adminSmsVerifyLogin) {
                userAPI.checkAdmin(v).then(response => {
                    //双要素验证
                    if (response.status != null && response.status == 200) {
                        if (response.data.admin) {
                            this.$message({
                                message: "已启用超管的密码 + 短信验证码双要素登录策略",
                                type: "info"
                            });
                            this.adminDcVerify = true
                            return;
                        }
                    }

                    this.adminDcVerify = false
                })
            }

        },

        //获取短信验证码
        getSmsCode(ref) {
            let valid = true;
            this.$refs[ref].clearValidate();
            // this.$refs[ref].validateField(["registName", "registImgCode"], err => {
            //   if (err != "") {
            //     valid = false;
            //   }
            // });

            if (valid) {
                let params = {
                    mobile: this.LoginForm.loginName
                };

                userAPI.getSmsCode(params).then(response => {
                    if (response.status != null && response.status == 200) {
                        this.waitCodeTime();
                    } else {
                        let message =
                            response.data.message == null ? "获取验证码失败" : response.data.message;
                        this.$message({
                            message: message,
                            type: "error"
                        });
                    }
                });
            }
        },


        //短信验证码倒计时
        waitCodeTime() {
            let waitSeconds = 60;
            this.getSmsCodeStatus = true;
            var interval = setInterval(() => {
                waitSeconds--;
                this.getSmsCodeName = "校验码已发送，请等待" + waitSeconds + "秒重试";
                this.getSmsCodeStatus = true;
                if (waitSeconds < 1) {
                    this.getSmsCodeName = "没收到验证码？重新获取";
                    this.getSmsCodeStatus = false;
                    clearInterval(interval);
                }
            }, 1000);
        },

        login() {
            this.$refs.loginFormRef.validate(valid => {
                if (valid) {
                    this.fullscreenLoading = true;
                    var param = { ...this.LoginForm }
                    if (this.adminDcVerify == true) {
                        param.smsVerify = this.LoginForm.smsCode //短信验证码设置为双要素的短信校验码
                        param.smsCode = ""//避免与短信验证码登录冲突
                    }
                    //加密密码防止泄露
                    param.passwordE = aes.encrypt(param.password)
                    param.password = null;
                    console.log(param);
                    if (this.hrsaas) {
                        param.platform = 7
                    }
                    if (this.isSaaS) {
                        param.platform = 2
                    }
                    console.log(param);
                    this["user/login"](param).then(data => {
                        this.fullscreenLoading = false;
                        if (data.success) {
                            console.log(data)
                            var tenantInfo = data.tenantInfo;
                            let organList = data.organList;//雨虹机构列表

                            if (tenantInfo?.domain) {
                                ls.set("tenantDomain", tenantInfo.domain.split(",")[0])
                            }

                            // 暂不需要跳转
                            // if (tenantInfo != null && tenantInfo.status != null
                            //     && (
                            //         (typeof tenantInfo.status == 'object' && [-7, -5].indexOf(tenantInfo.status.value) > -1) ||
                            //         (typeof tenantInfo.status == 'string' && ['已提交帐号', '已提交授权'].indexOf(tenantInfo.status) > -1)
                            //     ) && (this.isSaaS || this.hrsaas)) {
                            //     //saas注册用户的tenantInfo的状态还在待审核阶段，需转到到saas注册页面
                            //     // window.location.href = "login.html#/regSaas"
                            //     this.$router.push({
                            //         name: 'regSaas'
                            //     });
                            //     return;
                            // }
                            
                            if (sessionStorage.getItem("beforeLoginUrl") == null) {
                                if (data.user.firstLogin) {
                                    //首次登录
                                    this.$router.push({ name: "resetPass" });
                                } else {
                                    if (this.fromUrl != null && this.fromUrl) {
                                        window.location.href = decodeURIComponent(this.fromUrl);
                                    } else {
                                        //有雨虹机构且大于1个，则弹出机构选择
                                        if (organList && organList.length > 1) {
                                            this.organDialogVisible = true;
                                            this.organList = organList;
                                            if (this.$refs.organListRef)
                                                this.$refs.organListRef.search();
                                        } else {

                                            //判断默认进入的系统
                                            if (this.__menu.length == 1) {
                                                //一个系统菜单，只进这个系统
                                                const url = this.__menu[0].url;
                                                window.location.href = url;
                                            } else if (this.__menu.length > 1) {
                                                //多个系统菜单
                                                let manageFlag = false;
                                                for (var i = 0; i < this.__menu.length; i++) {
                                                    if (this.__menu[i].mxtype == 'manage') {
                                                        manageFlag = true;
                                                    }
                                                }
    
                                                if (manageFlag) {
                                                    window.location.href = "manage.html#/home";
                                                } else {
                                                    const url = this.__menu[0].url
                                                    window.location.href = url
                                                }
                                            } else {
                                                //无菜单
                                                this.$alert("无系统权限，将返回登录", "登录失败", {
                                                    confirmButtonText: "确定",
                                                    callback: action => {
                                                        this["user/logout"]().then(() => {
                                                            window.location.reload();
                                                        });
                                                    }
                                                });
                                            }
                                        }

                                    }
                                }

                            } else {
                                var beforeLoginUrl = sessionStorage.getItem("beforeLoginUrl")
                                sessionStorage.removeItem("beforeLoginUrl")
                                window.location.href = beforeLoginUrl;
                            }

                        } else {
                            let message = data.msg == null ? "" : data.msg;
                            if (message == "该号码未注册") {
                                // this.registBtn();
                                this.$message({
                                    message: "该手机号未注册，请您先注册！",
                                    type: "error"
                                });
                            } else {
                                if (data.errorCode == 407) {
                                    this.$message({
                                        message: "已启用超管的密码 + 短信验证码双要素登录策略",
                                        type: "info"
                                    });
                                    this.adminDcVerify = true
                                    return;
                                }

                                if (data.errorTimes >= 3 || message == "图片验证码不正确") {
                                    this.showVerify = true;
                                    ls.set("LoginError", 1);
                                }
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                                if (this.showVerify) {
                                    //刷新验证码
                                    this.changeImgCode();
                                }
                            }
                        }
                    });
                }
            });
        },
        qrLogin(result) {
            var param = { ...result }
            if (this.hrsaas) {
                param.platform = 7
            }
            if (this.isSaaS) {
                param.platform = 2
            }

            this["user/login"](param).then(data => {
                this.fullscreenLoading = false;
                if (data.success) {
                    console.log(data)
                    var tenantInfo = data.tenantInfo

                    if (tenantInfo?.domain) {
                        ls.set("tenantDomain", tenantInfo.domain.split(",")[0])
                    }

                    if (tenantInfo != null && tenantInfo.status != null
                        && (
                            (typeof tenantInfo.status == 'object' && [-7, -5].indexOf(tenantInfo.status.value) > -1) ||
                            (typeof tenantInfo.status == 'string' && ['已提交帐号', '已提交授权'].indexOf(tenantInfo.status) > -1)
                        ) && (this.isSaaS || this.hrsaas)) {
                        //saas注册用户的tenantInfo的状态还在待审核阶段，需转到到saas注册页面
                        // window.location.href = "login.html#/regSaas"
                        this.$router.push({
                            name: 'regSaas'
                        });
                        return;
                    }
                    if (sessionStorage.getItem("beforeLoginUrl") == null) {
                        if (data.user.firstLogin) {
                            //首次登录
                            this.$router.push({ name: "resetPass" });
                        } else {
                            if (this.fromUrl != null && this.fromUrl) {
                                window.location.href = decodeURIComponent(this.fromUrl);
                            } else {
                                //判断默认进入的系统
                                if (this.__menu.length == 1) {
                                    //一个系统菜单，只进这个系统
                                    const url = this.__menu[0].url;
                                    window.location.href = url;
                                } else if (this.__menu.length > 1) {
                                    //多个系统菜单
                                    let manageFlag = false;
                                    for (var i = 0; i < this.__menu.length; i++) {
                                        if (this.__menu[i].mxtype == 'manage') {
                                            manageFlag = true;
                                        }
                                    }

                                    if (manageFlag) {
                                        window.location.href = "manage.html#/home";
                                    } else {
                                        const url = this.__menu[0].url
                                        window.location.href = url
                                    }
                                } else {
                                    //无菜单
                                    this.$alert("无系统权限，将返回登录", "登录失败", {
                                        confirmButtonText: "确定",
                                        callback: action => {
                                            this["user/logout"]().then(() => {
                                                window.location.reload();
                                            });
                                        }
                                    });
                                }
                            }
                        }

                    } else {
                        var beforeLoginUrl = sessionStorage.getItem("beforeLoginUrl")
                        sessionStorage.removeItem("beforeLoginUrl")
                        window.location.href = beforeLoginUrl;
                    }

                } else {
                    let message = data.msg == null ? "" : data.msg;
                    if (message == "该号码未注册") {
                        // this.registBtn();
                        this.$message({
                            message: "该手机号未注册，请您先注册！",
                            type: "error"
                        });
                    } else {
                        if (data.errorCode == 407) {
                            this.$message({
                                message: "已启用超管的密码 + 短信验证码双要素登录策略",
                                type: "info"
                            });
                            this.adminDcVerify = true
                            return;
                        }

                        if (data.errorTimes >= 3 || message == "图片验证码不正确") {
                            this.showVerify = true;
                            ls.set("LoginError", 1);
                        }
                        this.$message({
                            message: message,
                            type: "error"
                        });
                        if (this.showVerify) {
                            //刷新验证码
                            this.changeImgCode();
                        }
                    }
                }
            });
        },
        registBtn() {
            if (this.hrsaas) {
                console.log("====hrsaas=======")
                this.$router.push({ name: "regHrsaas" });
                window.location.reload();
            } else if (this.isSaaS) {
                console.log("====saas=======")
                this.$router.push({ name: "regSaas" });
                window.location.reload();
            } else {
                this.$router.push({ name: "reg" });
            }
        },
        forgotPass() {
            this.$router.push({ name: "forgotPass" });
        },
        changeImgCode() {

            this.LoginForm.verify = "" //清空验证码

            this.verifyUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                process.env.VUE_APP_BACKEND_URL_PROXY +
                "/verify?randid=" +
                Math.abs(Math.sin(new Date().getTime()));
        },
        selectOrgan(val) {
            this.currentOrgan = val;
        },
        //确认选择机构
        confirmOrgan() {
            let _this = this;
            // console.log(_this.currentOrgan,'currentOrgan');
            let organ = _this.currentOrgan;
            this.$confirm('<span style="font-size:16px;">确定选择工厂机构【<span style="color:#F56C6C">'+ organ.organName +'</span>】，所属发货人</br>【<span style="color:#F56C6C">' + organ.shipperName +'</span>】吗？</span>', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: '',
                callback: (action, instance) => {
                    if (action == 'confirm') {
                        _this.confirmOrganLoading = true;
                        _this.organDialogVisible = false;//弹框
                        _this.fullscreenLoading = true;
                        let params = {
                            shipperId: organ.pid,
                            organId: organ.id
                        }
                        this["user/refreshShipperAndOrgan"](params).then(data => {
                            _this.confirmOrganLoading = false;
                            _this.fullscreenLoading = false;
                            if (data.success) {
                                //判断默认进入的系统
                                let menu = data.menuTree;
                                if (menu.length == 1) {
                                    //一个系统菜单，只进这个系统
                                    const url = menu[0].url;
                                    window.location.href = url;
                                } else if (menu.length > 1) {
                                    //多个系统菜单
                                    let manageFlag = false;
                                    for (var i = 0; i < menu.length; i++) {
                                        if (menu[i].mxtype == 'manage') {
                                            manageFlag = true;
                                        }
                                    }

                                    if (manageFlag) {
                                        window.location.href = "manage.html#/home";
                                    } else {
                                        const url = menu[0].url
                                        window.location.href = url
                                    }
                                } else {
                                    //无菜单
                                    this.$alert("无系统权限，将返回登录", "登录失败", {
                                        confirmButtonText: "确定",
                                        callback: action => {
                                            this["user/logout"]().then(() => {
                                                window.location.reload();
                                            });
                                        }
                                    });
                                }
                            }
                        })
                    }
                }
            })
        }
    },

    mounted() {
        var href = window.location.href;
        if (this.mobile) {
            this.LoginForm.loginName = this.mobile;
        }

        if (href.indexOf("hrsaas") > -1 || href.indexOf("saas.gz-vip.com") > -1) {
            this.hrsaas = true;
            this.loginRole = '工众人力平台';
            this.loginRoleName = '工众人力平台用户';
        } else if (window.location.host.indexOf("saas.") > -1) {
            this.saas = true;
        }
        if (this.saas != null) {
            this.loginRole = 'SaaS';
            this.isSaaS = true;
            this.loginRoleName = 'SaaS用户';
        }

        if (href.indexOf("zbrl.gsh56.com") > -1
            || href.indexOf("testzbrl.gsh56test.com") > -1
            // || href.indexOf("zbrl02.gsh56test.com") > -1
            // || href.indexOf("hr.gzh-vip.com") > -1
            || href.indexOf("testzbrl.gsh56test.com") > -1
            || href.indexOf("devzbrl.gsh56.com") > -1) {
            this.isZbrl = true;
        }

        var that = this
        setTimeout(function () {
            that.$refs['loginName'].focus()
        }, 250)

    },
};
</script>
<style>
.login-divider.el-divider--horizontal {
    margin: 12px 0;
}

.qrcode {
    width: 200px !important;
    height: 200px !important;
}

.rightBottomCss {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
    /* width: 50px;
        height: 50px; */
    /* background-color: red; */
}

.wrapper {
    position: relative;
    display: inline-block;
}

.triangle1 {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 40px solid rgb(255, 255, 255);
    border-bottom: 40px solid transparent;
    border-left: 40px solid rgb(255, 255, 255);
    border-right: 40px solid transparent;
    z-index: 20;
}

.triangle2 {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 30px solid rgb(255, 255, 255);
    border-bottom: 30px solid transparent;
    border-left: 30px solid rgb(255, 255, 255);
    border-right: 30px solid transparent;
    z-index: 20;
}

.img1CSS {
    display: block;
    position: relative;
    z-index: 10;
    width: 80px;
    height: 80px;
}

.img2CSS {
    display: block;
    position: relative;
    z-index: 10;
    width: 80px;
    height: 80px;
}


.bubble-wrap {
    width: 140px;
    height: 40px;
    background-color: #f9e4dc;
    position: absolute;
    bottom: 25px;
    right: 80px;
    color: #fe5918;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    border: 1px solid #fe5918;
}

.bubble-arrow {
    top: 8px;
    right: 0;
    position: absolute;
    z-index: 30;
}

.span1Css {
    border-left-color: #fe5918;
    left: 1px;
    border-width: 6px 6px 6px 6px;
    position: absolute;
    width: 0;
    height: 0;
    /* border-color: tomato; */
    border-style: solid;
    overflow: hidden;
    top: 5px;
    border-right-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 0);
    border-bottom-color: rgba(255, 255, 255, 0);
    z-index: 35;
}

.span2Css {
    border-left-color: #f9e4dc;
    border-width: 6px 6px 6px 6px;
    position: absolute;
    width: 0;
    height: 0;
    /* border-color: #f9e4dc; */
    border-style: solid;
    overflow: hidden;
    top: 5px;
    border-right-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 0);
    border-bottom-color: rgba(255, 255, 255, 0);
    left: 0;
    z-index: 40;
}

.mask {
    position: absolute;
    left: 24%;
    /* top: 0;
  left: 0; */
    width: 200px;
    height: 200px;
    background-color: #FFF;
    /* 蒙版层的颜色 */
    opacity: 0.9;
    /* 蒙版层的透明度, 可设置0-1之间的数值*/
}
</style>
