<template>
  <el-form ref="forgotFormRef" :model="forGotForm" class="login-form" :rules="rules">
    <div class="login-title">
      <h3>初次登录需要修改密码</h3>
    </div>
    <el-form-item>
      <el-input
        type="input"
        placeholder="手机号码"
        disabled
        v-model.trim="mobile"
        autocomplete="off"
        maxlength="11"
      >
        <template slot="prepend">
          <i class="el-icon-user" />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="registImgCode" style="margin-top: 22px">
      <el-row>
        <el-col :span="16">
          <el-input
            type="input"
            placeholder="图片验证码（区分大小写）"
            v-model.trim="forGotForm.registImgCode"
            maxlength="6"
            autocomplete="off"
          ></el-input>
        </el-col>
        <el-col :span="8" style="height:47px">
          <!-- <span style="height:47px"> -->
          <el-image
            @click="changeImgCode"
            fit="fill"
            :src="imgCode"
            style="width: 100%;height:47px;cursor: pointer;float:right"
          />
          <!-- </span> -->
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item prop="registCode" style="margin-top: 22px">
      <el-row type="flex" align="middle">
        <el-col :span="10">
          <el-input
            type="input"
            placeholder="短信验证码"
            v-model.trim="forGotForm.registCode"
            maxlength="6"
            autocomplete="off"
          ></el-input>
        </el-col>
        <el-col :span="14">
          <el-button
            type="primary"
            plain
            :disabled="this.getSmsCodeStatus"
            style="width:100%;height:47px"
            @click="getSmsCode('forgotFormRef')"
          >{{this.getSmsCodeName}}</el-button>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item prop="registPassword" style="margin-top: 22px">
      <el-input
        type="password"
        placeholder="新密码"
        v-model.trim="forGotForm.registPassword"
        autocomplete="off"
        maxlength="20"
      >
        <template slot="prepend">
          <i class="el-icon-lock" />
        </template>
      </el-input>
    </el-form-item>

    <el-button
      type="primary"
      style="width:100%;margin-bottom:10px;margin-top: 22px"
      @click="changePassword"
      v-loading.fullscreen.lock="fullscreenLoading"
    >提交</el-button>
    <div>
      <el-button type="text" style="width:20%;float:right" @click="backLogin">返回登录</el-button>
    </div>
  </el-form>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import userAPI from "@/api/userAPI.js";
import ls from "@/utils/ls";
export default {
  components: {},
  data() {
    var pwdPowerLengthFunc = (rule, value, callback) => {
      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,16}');
      if (!pwdRegex.test(value)) {
        callback(
          new Error("请输入8位以上包含数字和大小写字母组成的密码")
        );
      } else {
        callback();
      }
    };
    let checkMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      }
      let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
      if (!re.test(value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (value.length != 11) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    let checkSmsCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("短信验证码不能为空"));
      }
      let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
      if (!re.test(value)) {
        callback(new Error("请输入数字值"));
      } else {
        // if(value.length != 4){
        //   callback(new Error('请输入4位短信验证码'));
        // }else{
        callback();
        // }
      }
    };
    return {
      fullscreenLoading: false,
      imgCode:
        window.location.protocol +
        "//" +
        window.location.host +
        process.env.VUE_APP_BACKEND_URL_PROXY +
        "/verify?randid=" +
        Math.abs(Math.sin(new Date().getTime())),

      getSmsCodeName: "获取短信验证码",
      getSmsCodeStatus: false,
      mobile:'',
      forGotForm: {
        registName: "",
        registImgCode: "",
        registCode: "",
        registPassword: ""
      },
      rules: {
        registName: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change"
          },
          { validator: checkMobile, trigger: "blur" }
        ],
        registImgCode: [
          {
            required: true,
            message: "请输入图片验证码",
            trigger: "change"
          }
        ],
        registCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "change"
          },
          { validator: checkSmsCode, trigger: "blur" }
        ],
        registPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: "change"
          },
          { validator: pwdPowerLengthFunc }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({ 
      sysConfig: "config/sysConfig"
    })
  },
  created() {
    if(ls.get("login_info")){
      this.mobile = ls.get("login_info")['mobile']
      this.$parent.firstLogin = true
    }
  },
  methods: {
    ...mapActions(["user/login", "user/logout"]),
    backLogin() {
      this.$parent.signOut();
      //this.$router.push({ name: "login" });
    },
    //获取图片验证码
    changeImgCode() {
      this.imgCode =
        window.location.protocol +
        "//" +
        window.location.host +
        process.env.VUE_APP_BACKEND_URL_PROXY +
        "/verify?randid=" +
        Math.abs(Math.sin(new Date().getTime()));
    },

    //获取短信验证码
    getSmsCode(ref) {
      let valid = true;
      this.$refs[ref].clearValidate();
      this.$refs[ref].validateField(["registImgCode"], err => {
        if (err != "") {
          valid = false;
        }
      });

      if (valid) {
        let params = {
          userImgVerifyCode: this.forGotForm.registImgCode,
          mobile: this.mobile,
          type: "first_set_password"
        };
        userAPI.getSmsVerifyCode(params).then(response => {
          if (response.status != null && response.status == 200) {
            this.waitCodeTime();
          } else {
            let message =
              response.data.message == null ? "获取验证码失败" : response.data.message;
            this.$message({
              message: message,
              type: "error"
            });
          }
        });
      }
    },
    //注册按钮提交
    changePassword() {
      this.$refs.forgotFormRef.validate(valid => {
        if (valid) {
          this.fullscreenLoading = true;
          let params = {
            password: this.forGotForm.registPassword,
            mobile: this.mobile,
            smsCode: this.forGotForm.registCode,
            type: "first_set_password"
          };
          userAPI.resetPassWord(params).then(response => {
            this.fullscreenLoading = false;
            if (response.data.success) {
              this.$message({
                message: "密码修改成功",
                type: "success",
                duration:1000,
                onClose: () => {
                  this["user/logout"]().then(data => {
                    if (data) {
                      window.location.href = "/login.html";
                    }
                  });
                }
              });
            } else {
              let message =
                response.data.mag == null ? "修改失败" : response.data.mag;
              this.$message({
                message: message,
                type: "error"
              });
            }
          });
        }
      });
    },
    //短信验证码倒计时
    waitCodeTime() {
      let waitSeconds = 60;
      this.getSmsCodeStatus = true;
      var interval = setInterval(() => {
        waitSeconds--;
        this.getSmsCodeName = "校验码已发送，请等待" + waitSeconds + "秒重试";
        this.getSmsCodeStatus = true;
        if (waitSeconds < 1) {
          this.getSmsCodeName = "没收到验证码？重新获取";
          this.getSmsCodeStatus = false;
          clearInterval(interval);
        }
      }, 1000);
    },
    home() {
      window.location.href = "/index";
    }
  },

  mounted() {
     userAPI.reqBeforeVerify().then(response => {
      if (response.status != null && response.status == 200) {
          console.log('reqBeforeVerify')
          this.changeImgCode()
      } else {
        let message =
          response.data.message == null ? "failed" : response.data.message;
        this.$message({
          message: message,
          type: "error"
        });
      }
    });
  }
};
</script>